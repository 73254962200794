import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProfilesService } from '../profiles.service';
import { of } from "rxjs";

@Injectable()
export class ProfileListResolver implements Resolve<Observable<string>> {

    constructor(private profilesService: ProfilesService) { }

    resolve() {
        return this.profilesService.getProfiles().catch(() => {
            return of('error');
        });
    }

}

