<div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h4 fxFlex="50">Timelines</h4>
    <!-- <button [routerLink]="['/pending-timeline', 'new']" class="uk-button prama-button medium-button">
        <i class="fa fa-plus-circle faSize"></i>
    </button> -->

    <mat-form-field appearance="standard" class="tab-search">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input [(ngModel)]="filterValue">
      <button matSuffix mat-icon-button *ngIf="!filterValue">
        <mat-icon matSuffix>search</mat-icon>
      </button>
      <button matSuffix mat-icon-button *ngIf="filterValue" (click)="changeFilter('')">
        <mat-icon matSuffix>close</mat-icon>
      </button>

      
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
    <mat-tab-group class="w-100" (selectedIndexChange)="tabIndexClicked($event)" [selectedIndex]="initialFilterIndex">
      <mat-tab label="Pending ({{pendingTimelinesToShow.length}})">
      </mat-tab>
      <mat-tab label="Validated ({{validatedTimelinesToShow.length}})">
      </mat-tab>
      <mat-tab label="Rejected ({{rejectedTimelinesToShow.length}})">
      </mat-tab>
      <mat-tab label="Disabled ({{disabledTimelinesToShow.length}})">
      </mat-tab>      
    </mat-tab-group>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">

      <mat-table #table [dataSource]="dataSource" matSort class="timeline-table">

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef fxFlex="5%"> Id </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="5%">
            {{element.id}}
            <a [routerLink]="['/pending-timeline', element.id ]" class="mat-row-link"></a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timelineName">
          <mat-header-cell *matHeaderCellDef fxFlex="30%"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="30%">
            <h4>{{element.name}}</h4>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timelineCategory">
          <mat-header-cell *matHeaderCellDef fxFlex="30%"> Category </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="30%">
            {{element.categories}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timelineOwner">
          <mat-header-cell *matHeaderCellDef fxFlex="30%"> Owner </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="30%">
            {{element.creatorName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timelineCreationDate">
          <mat-header-cell *matHeaderCellDef fxFlex="15%"> Creation date </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="15%">
            {{element.creationDate | date: 'dd / MM / yyyy'}}<br>
            {{element.creationDate | date: 'H:mm'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timelineLastUpdate">
          <mat-header-cell *matHeaderCellDef fxFlex="15%"> Last update </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="15%">
            {{element.lastUpdate | date: 'dd / MM / yyyy'}}<br>
            {{element.lastUpdate | date: 'H:mm'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timelineImage">
          <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center">
            Image
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxLayoutAlign="end end">
            <img *ngIf="element && element.image" [src]="this.mediaUrl + element.image" class="list-image" />
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <!-- <mat-paginator [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons></mat-paginator> -->
    </div>
  </div>
</div>