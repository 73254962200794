import { Component, OnInit, Input, ViewChildren, ViewChild, SimpleChanges, AfterViewInit, Output, EventEmitter } from "@angular/core";

import { IExerciseDetail } from "../../interfaces/models/i-exercise-detail";
import { IExercisesFilter } from "../../interfaces/models/i-exercises-filter";
import { StationService } from "../../services/station.service";
import { ProgramService } from "../../services/program.service";
import { LevelService } from "../../services/level.service";
import { ExerciseService } from "../../services/exercise.service";
import { EquipmentService } from "../../services/equipment.service";
import { identifierModuleUrl } from "@angular/compiler";
import { ActivatedRoute } from "@angular/router";
import { ENUMS } from "../../../assets/enums";
import { AuthService } from "../../../app/services/auth.service";
import { IStation } from "../../interfaces/models/i-station";

@Component({
  selector: "exercise-filter",
  templateUrl: "./exercise-filter.component.html",
  styleUrls: ["./exercise-filter.component.css"]
})
export class ExerciseFilterComponent implements OnInit, AfterViewInit {
  stationFilterItems = [];
  programFilterItems = [];
  intensityFilterItems = [];
  complexityFilterItems = [];
  fundamentalMovementFilterItems = [];
  muscularGroupFilterItems = [];
  skillFilterItems = [];
  disciplineFilterItems = [];
  //equipmentFilterItems = [];

  exercisesFilter: IExercisesFilter;
  searchTypeTimeout = null;

  advancedFilters: boolean;

  filtersApplied: {
    search: IExerciseDetail[],
    station: IExerciseDetail[],
    program: IExerciseDetail[],
    programs: IExerciseDetail[],
    intensityLevel: IExerciseDetail[],
    complexityLevel: IExerciseDetail[],
    fundamentalMovement: IExerciseDetail[],
    muscularGroup: IExerciseDetail[],
    skills: IExerciseDetail[],
    discipline: IExerciseDetail[],
    equipment: IExerciseDetail[],
    userId: IExerciseDetail[]
  };

  ownerList: any[];

  @Input() exerciseList: IExerciseDetail[];
  @Input() exerciseListFiltered: IExerciseDetail[];
  @Input() filterStation: IStation;

  @Output() filterChange = new EventEmitter();

  @ViewChildren("filter") filters;
  @ViewChild("filtersearch") filtersearch;

  constructor(
    private stationsService: StationService,
    private programsService: ProgramService,
    private levelsService: LevelService,
    public exercisesService: ExerciseService,
    private equipmentService: EquipmentService,
    private authService: AuthService,
    private route: ActivatedRoute) {

    let fundamentalMovementCategories = [
      { id: 1, name: "Category 1" },
      { id: 2, name: "Category 2" },
      { id: 3, name: "Category 3" }
    ];

    //this.advancedFilters = this.authService.isInRole(ENUMS.roles.pavigym);
    this.advancedFilters = true;
    this.createOwnerList();

    let packages = this.route.snapshot.data.programList.filter(x => x.type === ENUMS.programTypes.package);
    let programs = this.route.snapshot.data.programList.filter(x => x.type === ENUMS.programTypes.program);

    //this.createFilters(packages, 'programs');
    //this.createFilters(this.route.snapshot.data.fundamentalMovementList, 'fundamentalMovement');

    if (this.advancedFilters) {
      this.createFilters(this.route.snapshot.data.stationList, 'station');
      this.createFilters(this.route.snapshot.data.programList, 'programs');
      this.createFilters(this.route.snapshot.data.intensityList, 'intensityLevel');
      this.createFilters(this.route.snapshot.data.complexityList, 'complexityLevel');
      this.createFilters(this.route.snapshot.data.fundamentalMovementList, 'fundamentalMovement');
      this.createFilters(this.route.snapshot.data.muscularGroupList, 'muscularGroup');
      this.createFilters(this.route.snapshot.data.skillList, 'skills');
      this.createFilters(this.route.snapshot.data.disciplineList, 'discipline');
      this.createFilters(this.ownerList, 'userId');
    } else {
    }
    this.createFilters(this.route.snapshot.data.equipmentList, 'equipment');


  }

  ngOnInit() {
    
    this.initFilters();
    this.exercisesService.resetAllFilters();

    // 'No material' filter for equipment
    let noMaterialFilter = {
      field: 'equipment',
      itemId: -1,
      name: 'No material',
      checked: false,
      comparisonField: null
    };
    if (this.exercisesService.filterList.findIndex(x => x.itemId === -1) === -1) {
      this.exercisesService.filterList.unshift(noMaterialFilter);
    }
    if (this.exerciseList)
      for (let exercise of this.exerciseList) {
        if (exercise.equipment && exercise.equipment.length === 0) {
          let noMaterial = { id: -1, name: 'No material' };
          exercise.equipment.push(noMaterial);
        }
      }

      // Estaciones cardio filtran por MACHINES
      if(this.filterStation.location > 0){
        this.exercisesService.setCheckedByName('machines');
      }
    
  }

  createOwnerList() {
    this.ownerList = [
      {
        id: this.authService.getUserId(),
        name: "Show only my exercises"
      }
    ];
  }

  ngAfterViewInit() {
    for (const filterCheck of this.filters._results) {
      filterCheck.nativeElement.checked = false;
    }
    this.applyFilters();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    //this.applyFilters();
  }

  applyFilters(noInit?) {
    let filtersActive = false;
    let searchActive = false;
    if (!noInit) {
      this.initFilters();
    }

    if (this.exerciseListFiltered !== undefined) {
      this.exerciseListFiltered.length = 0;

      if (this.filters !== undefined) {

        for (const filter of this.exercisesService.filterList) {
          if (filter.checked) {
            let ff = filter.field;
            let ff1 = filter.field + "1";
            let ff2 = filter.field + "2";

            this.exerciseList.forEach((x: IExerciseDetail) => {
              let f1: boolean, f2: boolean, f3: boolean, f4: boolean;
              if (x[ff] !== null) {
                if (filter.comparisonField === null) {
                  /*
                  let b1 = ff !== undefined && x[ff] !== undefined && x[ff] !== null;
                  let b2 = x[ff].id === filter.itemId || x[ff] === filter.itemId;
                  let b3 = Array.isArray(x[ff]);
                  let b4 = x[ff].filter(y => y.id === filter.itemId).length > 0;
                  let b5 = x[ff].indexOf(filter.itemId) > -1;
                  let b6 = x[ff].includes(filter.itemId);
                  */

                  f1 = ff !== undefined && x[ff] && x[ff] !== undefined && x[ff] !== null
                    && (
                      (x[ff].id === filter.itemId || x[ff] === filter.itemId) // not an array
                      || ((Array.isArray(x[ff]) // array property
                        && (x[ff].filter(y => y.id === filter.itemId).length > 0 || x[ff].indexOf(filter.itemId) > -1))));

                  f2 = ff1 !== undefined && x[ff1] !== undefined && x[ff1] !== null && (x[ff1].id === filter.itemId || x[ff1] === filter.itemId);
                  f3 = ff2 !== undefined && x[ff2] !== undefined && x[ff2].filter(y => y.id === filter.itemId).length > 0;
                } else {
                  f4 = filter.comparisonField !== null && (ff1 !== undefined && x[ff1] !== undefined && x[ff1] !== null && (x[ff1][filter.comparisonField] === filter.itemId));
                }
              }
              if ((f1 || f2 || f3 || f4) && !this.filtersApplied[ff].includes(x)) {
                this.filtersApplied[ff].push(x);
              }
            });

            if (this.filtersApplied[ff].length === 0) {
              this.filtersApplied[ff].push(null);
            }

            filtersActive = true;
          }
        }



        // SEARCH INPUT
        const searchValue = this.exercisesService.filterSearch;
        if (searchValue !== "") {
          var auxList = this.exerciseList.filter(x => x.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
            || x.id.toString().indexOf(searchValue) >= 0
            || x.tags.filter(y => y.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0).length > 0);
          for (const ex of auxList) {
            if (!this.filtersApplied['search'].includes(ex)) {
              this.filtersApplied['search'].push(ex);
            }
          }
          if (this.filtersApplied['search'].length === 0) {
            this.filtersApplied['search'].push(null);
          }
          searchActive = true;
        }

        let includeIt: boolean;

        if (this.exerciseList !== undefined && (filtersActive || searchActive || this.filterStation)) {
          for (const ex of this.exerciseList) {
            includeIt = true;
            for (const i in this.filtersApplied) {
              if (this.filtersApplied.hasOwnProperty(i) && includeIt) {
                // CHECKBOXES
                if (!this.filtersApplied[i].includes(ex) && this.filtersApplied[i].length > 0) {
                  includeIt = false;
                  break;
                }
              }
            }

            // Show alwaysVisible exercises only if filter is called from session exercise picker
            if (this.filterStation !== undefined) {
              includeIt = ex.alwaysVisible ? true : includeIt;
            }
            (ex.station !== null && (ex.station.id === 6 || ex.station.id === 8 || ex.station.id === 9))
            if (includeIt) {
              // if (this.filterStation) {
              //   //if ((ex.station !== null && (ex.station.id === this.filterStation.id || (this.filterStation.id !== 6 && this.filterStation.id !== 7)))) {
              //     if ((ex.station !== null)) {
                  this.exerciseListFiltered.push(ex);
              //   }
              // } else {
              //   this.exerciseListFiltered.push(ex);
              // }
            }
          }
        }

      }

    }

    // Si no hay filtros o las estaciones no son ni combo ni sprint
    // if (!filtersActive && !searchActive && this.exerciseList !== undefined && (this.filterStation.id !== 6 && this.filterStation.id !== 7)) {
    if (!filtersActive && !searchActive && this.exerciseList !== undefined) {
      this.exerciseListFiltered.length = 0;
      for (const ex of this.exerciseList) {
        this.exerciseListFiltered.push(ex);
      }
    }
    this.filterChange.emit();
  }

  doSearch() {
    clearTimeout(this.searchTypeTimeout);
    this.searchTypeTimeout = setTimeout(() => {
      this.applyFilters();
    }, 500);
  }

  initFilters() {
    this.filtersApplied = {
      search: [],
      station: [],
      program: [],
      programs: [],
      intensityLevel: [],
      complexityLevel: [],
      fundamentalMovement: [],
      muscularGroup: [],
      skills: [],
      discipline: [],
      equipment: [],
      userId: []
    };
  }

  resetFilters(filterField) {
    this.exercisesService.resetFilters(filterField);
    this.applyFilters();
  }

  createFilters(itemList: any[], field: string, comparisonField: string = null) {
    for (let item of itemList) {
      let filter = {
        field: field,
        itemId: item.id,
        name: item.name,
        checked: false,
        comparisonField: comparisonField
      };
      if (this.exercisesService.filterList.findIndex(x => x.field === field && x.itemId === item.id) === -1) {
        this.exercisesService.filterList.push(filter);
      }
    }
  }

  public getFilter(field: string, itemId: number): IExercisesFilter {
    let f = this.exercisesService.filterList.find(x => x.field === field && x.itemId === itemId);
    return this.exercisesService.filterList.find(x => x.field === field && x.itemId === itemId);
  }

  public getFilterList(field: string): IExercisesFilter[] {
    return this.exercisesService.filterList.filter(x => x.field === field);
  }

  public hasFiltersOf(field: string): boolean {
    for (const filter of this.exercisesService.filterList.filter(x => x.field === field)) {
      if (filter.checked === true) {
        return true;
      }
    }
    return false;
  }

}

