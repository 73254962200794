<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4 fxFlex="50">{{ userList?.length }} users</h4>
      <button (click)="createUser()" class="uk-button prama-button medium-button">
        <i class="fa fa-plus-circle faSize"></i>
      </button>
    </div>
    <mat-table #table [dataSource]="dataSource" matSort class="creator-table" *ngIf="userList?.length !== 0">

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Email </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="30%">
          <!-- <img [src]="'/assets/stations/' + element.station.id + '.png'" /> -->
          {{element.email}}
          <a (click)="editUser(element)" class="mat-row-link"></a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="30%">
          <h4>{{element.lastName + ', ' +element.name}}</h4>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="profiles">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Profiles </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="30%">
          <span *ngFor="let profile of element.profiles; let i = index">{{profile}}<span *ngIf="i < element.profiles.length-1">,&nbsp;</span></span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100, 500]" showFirstLastButtons></mat-paginator>
  </div>
</div>