<form (ngSubmit)="onSubmit()" #siteForm="ngForm">

  <h1>USER EDIT</h1>

  <!-- USER INFO -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">MAIN INFO</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="end start">
          <h3 *ngIf="user.id !== 0" class="color-white">#{{user.id}}</h3>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="100">
            <!-- EMAIL -->
            <mat-form-field>
              <input matInput #userEmail type="email" placeholder="Email" [value]="user.email" [(ngModel)]="user.email" name="userEmail" required>
              <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.email">
                Email is required
              </mat-hint>
            </mat-form-field>

            <div fxLayout="row" fxFlex="100" fxLayoutGap="20px">
              <div fxLayout="column" fxFlex="50">
                <!-- NAME -->
                <mat-form-field>
                  <input matInput #userName placeholder="Name" [value]="user.name" [(ngModel)]="user.name" name="userName" role="presentation">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.name">
                    Name is required
                  </mat-hint> -->
                </mat-form-field>
              </div>

              <div fxLayout="column" fxFlex="50">
                <!-- LAST NAME -->
                <mat-form-field>
                  <input matInput #userLastName placeholder="Last name" [value]="user.lastName" [(ngModel)]="user.lastName" name="userLastName">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.lastName">
                    Last name is required
                  </mat-hint> -->
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxFlex="100" fxLayoutGap="20px">
              <div fxLayout="column" fxFlex="50">
                <!-- GENDER -->
                <mat-form-field>
                  <mat-select #userGender placeholder="Gender" [(value)]="user.gender" name="userGender">
                    <mat-option value="m"> Male </mat-option>
                    <mat-option value="f"> Female </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayout="column" fxFlex="50">
                <!-- BIRTH DATE -->
                <mat-form-field>
                  <input matInput #userBirthDate placeholder="Birth date" [(ngModel)]="user.birthDate" name="userBirthDate" [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="space-between stretch">



          <div fxLayout="column" fxFlex="25">

          </div>

        </div>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch" class="posrel">

      <h5 fxFlex="nogrow">GEO DATA</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill class="w-100" fxLayoutAlign="start start">
          <div fxLayout="column" fxFlex="100">
            <div fxLayout="row" fxFlex="100" fxLayoutGap="20px">
              <div fxLayout="column" fxFlex="50">
                <!-- CITY -->
                <mat-form-field>
                  <input matInput #userCity placeholder="City" [value]="user.city" [(ngModel)]="user.city" name="userCity">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.city">
                  City is required
                </mat-hint> -->
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <!-- COUNTRY -->
                <mat-form-field>
                  <mat-select #userCountry placeholder="Country" [(value)]="user.country" name="userCountry">
                    <mat-option *ngFor="let country of this.globalService.countryList()" [value]="country">
                      {{ country }}
                    </mat-option>
                  </mat-select>
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.country">
                Country is required
              </mat-hint> -->
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100" fxLayoutGap="20px">
              <div fxLayout="column" fxFlex="50">
                <!-- ZIP CODE -->
                <mat-form-field>
                  <input matInput #userZip placeholder="Zip code" [value]="user.zip" [(ngModel)]="user.zip" name="userZip">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.zip">
                    Zip code is required
                  </mat-hint> -->
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <!-- Phone -->
                <mat-form-field>
                  <input matInput #userPhone placeholder="Phone" [value]="user.phone" [(ngModel)]="user.phone" name="userPhone">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.phone">
                    Phone is required
                  </mat-hint> -->
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

    </div>

  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <mat-toolbar class="mat-buttons-toolbar">
      <mat-toolbar-row>
        <span class="mat-spacer"></span>
        <button mat-flat-button type="submit" class="prama-button full-button medium-button">
          <mat-icon>save</mat-icon> SAVE
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>


  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="space-between stretch" fxFill>

    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">OTHER DATA</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill class="w-100" fxLayoutAlign="start start">
          <div fxLayout="column" fxFlex="100">
            <div fxLayout="row" fxFlex="100" fxLayoutGap="20px">
              <div fxLayout="column" fxFlex="50">

                <!-- PROFILES -->
                <mat-form-field>
                  <mat-select #userProfiles multiple placeholder="Profiles" [(ngModel)]="user.profiles" name="userProfiles" [compareWith]="compare">
                    <mat-option *ngFor="let profile of this.profileList" [value]="profile">
                      {{ profile.name }}
                    </mat-option>
                  </mat-select>
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.profiles">
                    Profiles required
                  </mat-hint> -->
                </mat-form-field>

                <!-- PRAMACLOUD USER -->
                <mat-form-field>
                  <mat-select #pramacloudUser placeholder="Pramacloud User" [(ngModel)]="user.idPramaCloud" name="pramacloudUser" required>
                    <mat-option *ngFor="let pramacloudUser of this.pramacloudUserList" [value]="pramacloudUser.pramacloudId">
                      {{ pramacloudUser.fullname }} ({{pramacloudUser.user}})
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.idPramaCloud">
                    Pramacloud user required
                  </mat-hint>
                </mat-form-field>

                <!-- PASSWORD -->
                <mat-form-field>
                  <input matInput #userPassword placeholder="Password" type="password" [value]="user.password" [(ngModel)]="user.password" name="userPassword" [required]="isNew" autocomplete="new-password">
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && !user.password && isNew">
                    Password is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

    </div>
  </div>
</form>