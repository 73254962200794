<ng-template #warmupCooldownModal>

    <div class="modal-content">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">PRE/POST SESSION</h4>            
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center">            
            <!-- Repeats Config -->
            <div fxLayout="column" fxLayoutAlign="start start">                
                <number-picker fxFlex="25" title="WarmUp time" [(number)]="wizardService.wizardData.warmupTime" min="0" max="999" increment="5" isTime="true" hasDefault="true"></number-picker>
                <number-picker fxFlex="25" title="CoolDown time" [(number)]="wizardService.wizardData.cooldownTime" min="0" max="999" increment="5" isTime="true" hasDefault="true"></number-picker>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
                <div class="button-round" fxLayoutAlign="center center" (click)="modalAction()">
                    <mat-icon>check</mat-icon>
                </div>
            </div>
        </div>
    </div>
</ng-template>