<ng-template #finisherModal>

    <div class="modal-content">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">Finisher Station</h4>
            <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button> -->
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center">
            <!-- Pregunta finisher Si/No -->
            <div fxLayout="column" fxLayoutAlign="start start" *ngIf="!finisherConfig">
                <div class="modal-selection" fxLayout="row" fxLayoutAlign="start center" (click)="modalAction(1)">
                    <div class="modal-selection-icon" fxLayoutAlign="center center">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div fxFlex>
                        Yes, add a finisher
                    </div>
                </div>
                <div class="modal-selection" fxLayout="row" fxLayoutAlign="start center" (click)="modalAction(0)">
                    <div class="modal-selection-icon" fxLayoutAlign="center center">
                        <mat-icon>close</mat-icon>
                    </div>
                    <div fxFlex>
                        No, skip the finisher
                    </div>
                </div>
            </div>

            <!-- Finisher Config -->
            <div fxLayout="column" fxLayoutAlign="start start" *ngIf="finisherConfig">
                <number-picker fxFlex="25" title="How many finisher stations?" [(number)]="wizardService.finisherStations.stations" min="1" max="15" increment="1"></number-picker>
                <number-picker fxFlex="25" title="Time between functional and finisher zones" [(number)]="wizardService.finisherStations.timeFinisherStations" min="5" max="999" increment="5" isTime="true"></number-picker>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="w-100" *ngIf="finisherConfig">
                <div class="button-round" fxLayoutAlign="center center" (click)="finisherStationsAction(2)">
                    <mat-icon>check</mat-icon>
                </div>
            </div>
        </div>
    </div>
</ng-template>