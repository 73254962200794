<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <!-- <div fxLayout="column" fxFlex="20" fxFlexAlign="stretch">

  </div> -->

  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 fxFlex="50">{{ sessionsToShow?.length }} finishers</h4>
        <!-- <button [routerLink]="['/finisher', 'new']" class="uk-button prama-button medium-button">
            <i class="fa fa-plus-circle faSize"></i>
        </button> -->
    </div>

    <mat-table #table [dataSource]="dataSource" matSort class="session-table">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="5%">
          <!-- <img [src]="'/assets/stations/' + element.station.id + '.png'" /> -->
          {{element.id}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sessionName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40%"> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="40%">
          <h4>{{element.name}}</h4>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sessionDescription">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%"> Description </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15%">
          {{element.description}}
        </mat-cell>
      </ng-container>
 
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectFinisher(row)"></mat-row>
    </mat-table>
  </div>
</div>
