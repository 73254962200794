<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4 fxFlex="50">{{ profileList?.length }} profiles</h4>
      <button (click)="createProfile()" class="uk-button prama-button medium-button">
        <i class="fa fa-plus-circle faSize"></i>
      </button>
    </div>
    <mat-table #table [dataSource]="dataSource" matSort class="creator-table profiles-table" *ngIf="profileList?.length !== 0">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10"> Id </mat-header-cell>
        <mat-cell *matCellDef="let profile" fxFlex="10">
          {{profile.id}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20"> Name </mat-header-cell>
        <mat-cell *matCellDef="let profile" fxFlex="20">
          <h4>{{profile.name}}</h4>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30"> Description </mat-header-cell>
        <mat-cell *matCellDef="let profile" fxFlex="30">
          {{profile.description}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="roles">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30"> Roles </mat-header-cell>
        <mat-cell *matCellDef="let profile" fxFlex="40">
          <ng-container *ngIf="editMode != profile.id">          
            <span *ngFor="let role of profile.roles">
              {{role.name}},&nbsp; 
            </span>            
          </ng-container>
          <ng-container *ngIf="editMode == profile.id">
            <mat-form-field class="w-100 mr-3">
              <mat-select #profileRoles multiple placeholder="Roles" [compareWith]="compare" [(ngModel)]="profile.roles" (selectionChange)="rolesChanged(profile)" name="profileRoles" class="w-100">
                <mat-option *ngFor="let role of rolesAvailable" [value]="role">
                  {{role.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="10"> Actions </mat-header-cell>
        <mat-cell *matCellDef="let profile" fxFlex="10">
          <button mat-icon-button (click)="openEditor(profile)" *ngIf="editMode != profile.id">
            <mat-icon title="Edit">mode_edit</mat-icon>
          </button>
          <button mat-icon-button (click)="saveProfile(profile)" *ngIf="editMode == profile.id && !newMode">
            <mat-icon title="Save">save</mat-icon>
          </button>
          <button mat-icon-button (click)="createProfile(profile)" *ngIf="profile.id == 0 && newMode && profile.idParent !== null">
            <mat-icon title="Save">check</mat-icon>
          </button>
          <button mat-icon-button (click)="modalRemoveProfileOpen(profile)" *ngIf="profile.id == editMode && !newMode">
            <mat-icon title="Delete">delete</mat-icon>
          </button>
          <button mat-icon-button (click)="resetForm($event, profile, true)" *ngIf="profile.id == editMode">
            <mat-icon title="Cancel">close</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100, 500]" showFirstLastButtons></mat-paginator>
  </div>
</div>
