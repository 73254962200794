import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WizardService } from '../../../services/wizard.service';
import { IStation } from '../../../interfaces/models/i-station';

@Component({
  selector: 'finisher-select-modal',
  templateUrl: './finisher-select-modal.component.html',
  styleUrls: ['./finisher-select-modal.component.scss']
})
export class FinisherSelectModalComponent implements OnInit {

  @ViewChild("finisherSelectModal") finisherSelectModal;

  modalRef: BsModalRef;
  
  constructor(private modalService: BsModalService,
    private wizardService: WizardService) { }

  ngOnInit(): void {
  }

  open(){
    this.openModal();
  }

  public openModal() {
    this.modalRef = this.modalService.show(this.finisherSelectModal, Object.assign({}, { class: 'modal-dialog-centered modal-dialog modal-xl' }));
  }
  public closeModal() {
    this.modalRef.hide();
  }

  public finisherSelected($event){
    this.wizardService.wizardData.finisher.finisher = $event;
    this.closeModal();
  }


}
