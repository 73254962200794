<div class="wizard-exercises-container" fxFlex fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="space-between stretch">
    <div fxFlex fxLayout="row wrap" fxLayoutGap="0px">
        <!-- Estaciones estandar -->
        <div class="wizard-exercise-block" fxFlex="25" *ngFor="let station of getStations(0)">
            <div class="wizard-exercise" fxFlex>
                <div class="wizard-exercise-image" *ngIf="station.selectedExercise" [ngStyle]="{'background-image': 'url('+ exercisesMediaURL + station.selectedExercise?.thumbnail+')'}">
                    <!-- <img [src]="exercisesMediaURL + station.selectedExercise?.thumbnail"> -->
                </div>
                <!-- <div class="wizard-exercise-name" *ngIf="station.id == 6 || station.id == 7">
                    {{station.name}}
                </div>
                <div class="wizard-exercise-icon" *ngIf="station.id == 6 || station.id == 7">
                    <img src="assets/stations/{{station.id}}.png" *ngIf="station.id !== 0">
                </div> -->
                <div class="wizard-exercise-add-button" fxLayout="row" fxLayoutAlign="center center" (click)="exerciseClicked(station)">
                    <!-- Menu Options -->
                    <div *ngIf="!station.selectedExercise">                        
                        <img src="assets/img/exerciseIcon.png" (click)="openSelectExerciseModal(station, $event)">
                    </div>
                    <div class="exercise-menu-options p-2" *ngIf="station.selectedExercise && station.optionsMenu">
                        <img src="assets/img/exerciseDeleteIcon.png" class="m-2" (click)="clearStationExercise(station)">
                        <img src="assets/img/exerciseIcon.png" class="m-2" (click)="openSelectExerciseModal(station, $event)">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div fxFlex="20" [fxLayout]="getCardioStationLayout()" fxLayoutGap="0px" *ngIf="getStations(1).length > 0" class="cardio-exercises-container">
        <!-- Estaciones cardio -->
        <div class="wizard-exercise-block" [fxFlex]="getCardioStationSize()" *ngFor="let station of getStations(true)">
            <div class="wizard-exercise" fxFlex>
                <div class="wizard-exercise-image" *ngIf="station.selectedExercise" [ngStyle]="{'background-image': 'url('+ exercisesMediaURL + station.selectedExercise?.thumbnail+')'}">
                    <!-- <img [src]="exercisesMediaURL + station.selectedExercise?.thumbnail"> -->
                </div>
                <div class="wizard-exercise-name">
                    {{station.name}}
                </div>
                <div class="wizard-exercise-icon">
                    <img src="assets/stations/{{station.id}}.png" *ngIf="station.id !== 0">
                </div>
                <div class="wizard-exercise-add-button" fxLayout="row" fxLayoutAlign="center center" (click)="openSelectExerciseModal(station)">
                    <img src="assets/img/exerciseIcon.png" *ngIf="!station.selectedExercise">
                </div>
            </div>
        </div>
    </div>
</div>
<exercise-select-modal></exercise-select-modal>