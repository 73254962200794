import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UsersService } from '../users.service';
import { of } from "rxjs";

@Injectable()
export class PramacloudUserListResolver implements Resolve<Observable<string>> {

    constructor(private usersService: UsersService) { }

    resolve() {
        return this.usersService.getPramacloudUsers().catch(() => {
            return of('error');
        });
    }

}

