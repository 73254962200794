import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from "../../services/auth.service";
import { ENUMS } from "../../../assets/enums";
import { IProfile } from "../../interfaces/i-profile";
import { IRole } from "../../interfaces/i-role";
import { ProfilesService } from "../../services/profiles.service";
import { getRtlScrollAxisType } from "@angular/cdk/platform";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "profile-list",
  templateUrl: "./profile-list.component.html",
  styleUrls: ["./profile-list.component.css"]
})
export class ProfileListComponent implements OnInit, AfterViewInit {

  headers = {
    id: "Id",
    name: "Name",
    description: "Description",
    roles: "Roles",
    actions: "Actions"
  };

  profiles: IProfile[];

  profileList: IProfile[] = [];
  profileListRange: number = 0;
  profileListAddOnEachScroll: number = 10;
  rolesAvailable: IRole[] = [];

  previewImage = "";

  pavigym: boolean;
  userId: number;

  editMode: number;
  newMode: boolean = false;
  tempProfile: IProfile;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Profile list
  displayedColumns = ['id', 'name', 'description', 'roles', 'actions'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute, 
    private router: Router, 
    private authService: AuthService, 
    private profilesService: ProfilesService,
    private notificationService: NotificationService) {
    this.profileList = this.route.snapshot.data.profiles;
    this.rolesAvailable = this.route.snapshot.data.rolesAvailable;
    this.pavigym = this.authService.isInRole(ENUMS.roles.pavigym);
    this.userId = this.authService.getUserId();

    //this.profilesToShow = this.profilesToShow.slice(0, 40); // Limit to 40 for debugging
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterViewInit() {
    // for (const ex of this.profilesToShow) {
    //   this.profileList.push(ex);
    // }
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.updateTableData();
  }

  loadDataOnTable() {
    for (let i = this.profileListRange; i < this.profileListRange + this.profileListAddOnEachScroll; i++) {
      // this.profileList.push(this.profilesToShow[i]);
    }
    this.profileListRange += this.profileListAddOnEachScroll;
  }

  updateTableData() {
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.profileList);
    this.dataSource.paginator = this.paginator;
  }

  public rolesChanged(profile: IProfile) {

  }

  private saveProfile(profile: IProfile) {
    this.profilesService.putProfile(profile).subscribe(
      result => {
        this.notificationService.notify("Profile modified successfully",
        ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
      },
      error => {
        console.log('error saving profile');
      }
    )
  }

  openEditor(profile: IProfile) {
    this.editMode = profile.id;

    this.tempProfile = JSON.parse(JSON.stringify(profile));
    // if (this.newMode) {
    //   this.categoryListToShow = this.categoryListToShow.filter(x => x.id != 0);
    // }
    // this.dataSource = new MatTableDataSource(this.categoryListToShow);
  }

  resetForm(event, profile, canceled) {
    try {
      event.stopPropagation();
    } catch (e) {
    }

    if (canceled) {
      this.editMode = 0;
      this.newMode = false;

      profile.name = this.tempProfile.name;
      profile.description = this.tempProfile.description;
      profile.roles = JSON.parse(JSON.stringify(this.tempProfile.roles));
    }
    else {
      // this.categoriesService.getCategories().subscribe(
      //   result => {
      //     this.categoryTree = result;
      //     this.categoryList = this.categoriesService.convertTreeToList(result);
      //     this.categoryListToShow = this.categoriesService.convertTreeToList(result);
      //     this.editMode = 0;
      //     this.newMode = false;
      //     this.dataSource = new MatTableDataSource(this.categoryListToShow);
      //   },
      //   error => {
      //     console.log('Error');
      //   }
      // );
    }
  }

  createProfile() {
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

}
