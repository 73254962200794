<ng-template #heartrateModal>

    <div class="modal-content">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">Heart Rate</h4>
            <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button> -->
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center">
            <!-- Pregunta heartrate Si/No -->
            <div fxLayout="column" fxLayoutAlign="start start" *ngIf="!heartrateConfig">
                <div class="modal-selection" fxLayout="row" fxLayoutAlign="start center" (click)="modalAction(1)">
                    <div class="modal-selection-icon" fxLayoutAlign="center center">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div fxFlex>
                        Activate Heart Rate
                    </div>
                </div>
                <div class="modal-selection" fxLayout="row" fxLayoutAlign="start center" (click)="modalAction(0)">
                    <div class="modal-selection-icon" fxLayoutAlign="center center">
                        <mat-icon>close</mat-icon>
                    </div>
                    <div fxFlex>
                        Discard Heart Rate
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>