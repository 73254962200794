import { NotificationService } from './../../services/notification.service';
import { CategoriesService } from './../../services/categories.service';
import { Category } from './../../interfaces/models/category';
import { ITimelineValidation } from './../../interfaces/models/i-timeline-validation';
import { SessionService } from './../../services/session.service';
import { Component, DebugElement, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ITimeline, ITimelineObjects, ICountdown, ITimelineWelcome, ITimelineExplanation, ITimelineWarmUp, ITimelinePositioningGt, ITimelinePositioningFinisher, ITimelineResultsGt, ITimelineResultsFinisher, ITimelineCooldown, ITimelineEnding, ITimelineSession } from "../../interfaces/models/i-timeline";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { TimelineService } from "../../services/timeline.service";
@Component({
  selector: "pending-timeline-form",
  templateUrl: "./pending-timeline-form.component.html",
  styleUrls: ["./pending-timeline-form.component.css"]
})
export class PendingTimelineFormComponent implements OnInit {

  isNew: Boolean = false;

  timeline: any;
  session: any;
  finisher: any;
  categories: Category[];
  timelineLog: any;

  categoriesList: any[];

  timelineValidation: ITimelineValidation = {
    timelineId: null,
    status: null,
    categories: [],
    name: null,
    description: null
  };

  formSubmitted = false;

  exercisesMediaURL: string = CONFIG.exercisesMediaURL;

  sessionExercisesZone1: any[];
  sessionExercisesZone2: any[];
  cardioExercises: any[];
  finisherExercises: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private timelineService: TimelineService,
    private sessionService: SessionService,
    private categoriesService: CategoriesService,
    private notificationService: NotificationService
  ) {


  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.timeline = this.route.snapshot.data.timeline;
    this.categories = this.route.snapshot.data.categories;
    this.categoriesList = this.categoriesService.convertTreeToList(this.categories);
    this.timelineLog = this.route.snapshot.data.timelineLog;

    if (this.timeline.session.id !== 0) {
      this.sessionService.getSession(this.timeline.session.id).subscribe(
        result => {
          this.session = result;
          this.sessionExercisesZone1 = this.session.exercises.filter(ex => ex.zone === 1);
          this.sessionExercisesZone2 = this.session.exercises.filter(ex => ex.zone === 2);

          if (this.timeline.cardioExercises.length > 0) {
            this.cardioExercises = this.timeline.cardioExercises;
            this.sortExercises(this.cardioExercises);
          }
          this.sortExercises(this.sessionExercisesZone1);
          this.sortExercises(this.sessionExercisesZone2);
        });
    }

    if (this.timeline.finisher.id !== 0) {
      this.sessionService.getSession(this.timeline.finisher.id).subscribe(
        result => {
          this.finisher = result;
          this.finisherExercises = this.finisher.exercises;
          this.sortExercises(this.finisherExercises);
        });
    }
    if (this.timeline.categories !== null) {
      this.timeline.categories.forEach(category => {
        this.timelineValidation.categories.push(category);
      });
    }

    this.timelineLog = this.timelineLog.reverse();

  }

  // ESTADOS
  // 0 = eliminado
  // 1 = activo
  // 2 = pendiente
  // 3 = rechazado
  // 4 = deshabilitado
  
  // CAMBIOS POSIBLES
  // pendiente a activo cuando se selecciona una categoría
  // pendiente a rechazado cuando se rechaza
  // activo a deshabilitado cuando se deshabilita desde la vista de detalle (se elimina su categoría)
  // deshabilitado a activo cuando se activa desde la vista de detalle y se asigna categoria
  public changeStatus(status: string) {
    this.timelineValidation.timelineId = this.timeline.id;
    this.timelineValidation.name = this.timeline.name;
    this.timelineValidation.description = this.timeline.description;
    this.timelineValidation.status = ENUMS.timelineValidationStatus[status];
    
    // Si status es deshabilitado se eliminan las categorias
    if(this.timelineValidation.status === 4){
      this.timelineValidation.categories = [];
    }

    this.timelineService.postTimelineValidation(this.timelineValidation).subscribe(
      result => {
        let url = "/pending-timelines";
        let responseText: string;
        switch (status) {
          case "active":
            responseText = "Timeline validated successfully";
            break;
          case "rejected":
            responseText = "Timeline rejected successfully";
            break;
          default:
            responseText = "Timeline is now: " + status;
        }
        this.notificationService.notify(responseText,
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);

        this.router.navigate([url]);
      });
  }

  // Devuelve true si el item tiene que mostrarse como checked o no, dependiendo de si se encuentra en la lista
  public isChecked(item: any, items: number[]) {
    return items.indexOf(item.id) > -1;
  }

  // Inserta el item en el listado o lo borra si existe
  public toggleChecked(item: any, itemList: number[], $event) {

    // Si es solo un item seleccionable, vaciamos array
    itemList.length = 0;

    if ($event.checked) {
      itemList.push(item.id);
    } else {
      let index = itemList.indexOf(item.id);
      itemList.splice(index, 1);
    }
  }

  // Ordenamos exercises
  private sortExercises(exerciseList: any[]) {
    exerciseList.forEach(exercise => {
      this.assignOrder(exercise);
    })
    exerciseList.sort(
      function (a, b) {
        // if (a.station === b.station) {
        //   // Si station es el mismo, usamos order para ordenar
        //   return a.order - b.order;
        // }
        //return a.station > b.station ? 1 : -1;
        return a.showOrder - b.showOrder;
      });
  }

  // Ordenación de estaciones por puntos. Se asignan puntos en base al id de estacion y orden
  // Se guarda en el campo showOrder
  // Luego ordenaremos por ese campo
  private assignOrder(exercise) {
    const maxStations = 20; //estaciones máximas por grupo, un limite lógico
    switch (exercise.station) {
      // Grupo 1
      case 6: exercise.showOrder = 1; break;
      case 8: exercise.showOrder = 2; break;
      case 9: exercise.showOrder = 3; break;
      case 7: exercise.showOrder = 4; break;

      // Grupo 3
      case 11: exercise.showOrder = 100000; break;

      // Grupo 2
      default: exercise.showOrder = 1000; break;
    }
    // Añadimos puntos por orden
    exercise.showOrder += (exercise.order - 1) * maxStations;
  }


}
