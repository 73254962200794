<div class="header" *ngIf="showHeader">
  <mat-toolbar>
    <mat-toolbar-row>
      <div class="page-title">
        <a [routerLink]="['']">
          <img src="./../../assets/img/logo-bootiq.png" class="logo" />
        </a>
      </div>

      <span class="mat-shortspacer"></span>

      <!-- <a mat-button [routerLink]="['/exercises']" *ngIf="this.accessExercises">EXERCISES</a> -->

      <!-- <a mat-button [matMenuTriggerFor]="gtmenu" *ngIf="this.pavigym">GROUP TRAINING</a>
      <mat-menu #gtmenu="matMenu">
        <a mat-menu-item [routerLink]="['/sessions']" *ngIf="this.accessGroupTrainingSessions">SESSIONS</a>
        <a mat-menu-item [routerLink]="['/finishers']" *ngIf="this.accessGroupTrainingFinishers">FINISHERS</a>
        <a mat-menu-item [routerLink]="['/timelines']" *ngIf="this.accessGroupTrainingTimelines">TIMELINES</a>
        <a mat-menu-item [routerLink]="['/workouts']" *ngIf="this.accessGroupTrainingWorkouts && this.pavigym">WORKOUTS</a>
      </mat-menu>
      <a mat-button [routerLink]="['/workouts']" *ngIf="this.accessGroupTrainingWorkouts && !this.pavigym">WORKOUTS</a> -->

      <!-- <a mat-button [matMenuTriggerFor]="programsmenu" *ngIf="this.accessProgramManager || this.accessProgramContent || this.accessProgramBasics">PROGRAMS</a>
      <mat-menu #programsmenu="matMenu">
        <a mat-menu-item  [routerLink]="['/packages']" *ngIf="this.accessProgramManager">MANAGER</a>
        <a mat-menu-item [routerLink]="['/programs']" *ngIf="this.accessProgramContent">CONTENT</a>
        <a mat-menu-item  [routerLink]="['/basics']" *ngIf="this.accessProgramBasics">BASICS</a>
      </mat-menu> -->

      <!-- <a mat-button [routerLink]="['/music']" *ngIf="this.accessMusic">MUSIC</a>
      <a mat-button [routerLink]="['/homefitness']" *ngIf="this.accessHomeFitness">HOME FITNESS</a> -->
      <a mat-button [routerLink]="['/my-timelines']" *ngIf="this.accessWizard">CREATOR</a>
      <a mat-button [routerLink]="['/categories']" *ngIf="this.accessCategories">CATEGORIES</a>
      <a mat-button [routerLink]="['/pending-timelines']" *ngIf="this.accessValidation">VALIDATION</a>
      <a mat-button [routerLink]="['/sessions-by-category']" *ngIf="this.accessUsers">SESSIONS BY CATEGORY</a>
      <a mat-button [routerLink]="['/users']" *ngIf="this.accessUsers">USERS</a>
      <!-- <a mat-button [routerLink]="['/profiles']" *ngIf="true">PROFILES</a> -->

      <span class="mat-spacer"></span>

      <button mat-button href="javascript:;" (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        <span>LOGOUT</span>
      </button>

    </mat-toolbar-row>
  </mat-toolbar>
</div>
