<ng-template #circuitRepeatModal>

    <div class="modal-content">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">Circuits</h4>
            <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button> -->
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center">            
            <!-- Repeats Config -->
            <div fxLayout="column" fxLayoutAlign="start start">
                <number-picker fxFlex="25" title="How many times do you want to do the workout?" [(number)]="wizardService.wizardData.circuitRepeatTimes" min="1" max="15" increment="1"></number-picker>
                <number-picker fxFlex="25" title="Time between circuits" [(number)]="wizardService.wizardData.timeBetweenCircuits" min="5" max="999" increment="5" isTime="true" *ngIf="wizardService.wizardData.circuitRepeatTimes>1"></number-picker>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
                <div class="button-round" fxLayoutAlign="center center" (click)="modalAction()">
                    <mat-icon>check</mat-icon>
                </div>
            </div>
        </div>
    </div>
</ng-template>