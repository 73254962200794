import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WizardService } from '../../../services/wizard.service';
import { IStation } from '../../../interfaces/models/i-station';

@Component({
  selector: 'warmup-cooldown-modal',
  templateUrl: './warmup-cooldown-modal.component.html',
  styleUrls: ['./warmup-cooldown-modal.component.scss']
})
export class WarmupCooldownModalComponent implements OnInit {

  @Output() doStepProcedures = new EventEmitter();
  
  @ViewChild("warmupCooldownModal") warmupCooldownModal;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService,
    private wizardService: WizardService) { }

  ngOnInit(): void {
  }

  public open() {
    this.modalRef = this.modalService.show(this.warmupCooldownModal, { class: 'modal-dialog-centered modal-md wizard-modal', ignoreBackdropClick: true });
  }
  public close() {
    this.modalRef.hide();
  }

  public modalAction() {
    this.wizardService.goToNextStep();
    this.doStepProcedures.emit();
    this.close();
  }



}
