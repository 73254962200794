import { Component, OnInit } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { LoadingService } from "../../services/loading.service";
import 'hammerjs';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  title = "Prama Bootiq";

  constructor(private router: Router, private loadingService: LoadingService) {
    router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.loadingService.showSpinner();
      } else if (evt instanceof NavigationEnd) {
        this.loadingService.hideSpinner();
      }
    });
  }
  ngOnInit(): void {
    console.log(`${Date.now()} APP STARTED`);
  }

}