import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WizardService } from '../../../services/wizard.service';
import { IStation } from '../../../interfaces/models/i-station';

@Component({
  selector: 'cardio-modal',
  templateUrl: './cardio-modal.component.html',
  styleUrls: ['./cardio-modal.component.scss']
})
export class CardioModalComponent implements OnInit {

  @Output() doStepProcedures = new EventEmitter();

  @ViewChild("cardioModal") cardioModal;
  modalRef: BsModalRef;

  cardioConfig: boolean = false;

  constructor(private modalService: BsModalService,
    private wizardService: WizardService) { }

  ngOnInit(): void {
  }

  public open() {
    this.modalRef = this.modalService.show(this.cardioModal, { class: 'modal-dialog-centered modal-md wizard-modal', ignoreBackdropClick: true });
  }
  public close() {
    this.modalRef.hide();
  }

  public modalAction(action: number) {
    switch (action) {
      case 0:
        this.wizardService.cardioStations = 0;
        this.wizardService.goToStep('circuitRepeatToggle');
        this.doStepProcedures.emit();
        this.close();
        break;
      case 1:
        this.wizardService.cardioStations = 1;
        this.wizardService.wizardData.timeBetweenFunctionalAndCardioStations = 45;
        this.cardioConfig = true;
        this.wizardService.goToStep('cardioStationConfig');
        break;
      case 2:
        this.wizardService.cardioConfigSetup();
        this.wizardService.goToStep('cardioStationExercisesSelect');
        this.close();
        break;
    }
  }



}
