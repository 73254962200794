import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { IMood } from '../../interfaces/models/i-mood';
import { IWorkoutMode } from '../../interfaces/models/i-workout-mode';
import { ISession } from '../../interfaces/models/i-session';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IExecutionMode } from '../../interfaces/models/i-execution-mode';
import { CardioModalComponent } from './cardio-modal/cardio-modal.component';
import { CircuitRepeatModalComponent } from './circuit-repeat-modal/circuit-repeat-modal.component';
import { WizardService } from '../../services/wizard.service';
import { CONFIG } from "../../../assets/config";
import { FinisherModalComponent } from './finisher-modal/finisher-modal.component';
import { FinisherSelectModalComponent } from './finisher-select-modal/finisher-select-modal.component';
import { HeartrateModalComponent } from './heartrate-modal/heartrate-modal.component';
import { AmbienceSelectModalComponent } from './ambience-select-modal/ambience-select-modal.component';
import { NameModalComponent } from './name-modal/name-modal.component';
import { SessionService } from '../../services/session.service';
import { SessionCopyModalComponent } from './session-copy-modal/session-copy-modal.component';
import { ExerciseService } from '../../services/exercise.service';
import { WarmupCooldownModalComponent } from './warmup-cooldown-modal/warmup-cooldown-modal.component';


@Component({
  selector: 'wizard-creator',
  templateUrl: './wizard-creator.component.html',
  styleUrls: ['./wizard-creator.component.scss']
})
export class WizardCreatorComponent implements OnInit {


  modalRef: BsModalRef;

  public workoutModesAvailable: IWorkoutMode[];
  moodsAvailable: IMood[];

  public session: ISession;
  public CONFIG = CONFIG;

  @ViewChild(CardioModalComponent) cardioModalComponent;
  @ViewChild(CircuitRepeatModalComponent) circuitRepeatModalComponent;
  @ViewChild(WarmupCooldownModalComponent) warmupCooldownModalComponent;
  @ViewChild(FinisherModalComponent) finisherModalComponent;
  @ViewChild(FinisherSelectModalComponent) finisherSelectModal;
  @ViewChild(HeartrateModalComponent) heartrateModalComponent;
  @ViewChild(AmbienceSelectModalComponent) ambienceSelectModalComponent;
  @ViewChild(NameModalComponent) nameModalComponent;
  @ViewChild(SessionCopyModalComponent) sessionCopyModalComponent;

  finisherOptionsMenu = false;
  routerSubscription;

  timeline: any;
  finisher: any;

  selectedTab: number = 1;

  constructor(private modalService: BsModalService,
    private route: ActivatedRoute,
    public wizardService: WizardService,
    private router: Router,
    private sessionService: SessionService,
    private exerciseService: ExerciseService) {
  }
  
  ngOnInit(): void {
    this.wizardService.wizardData = null;
    this.initializeSession();
    
    this.loadData();
    //this.wizardService.initializeData();

    // Subscribimos a evento para poder recargar componente si se carga la misma ruta
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadData();
        this.wizardService.initializeData();
      }
    });
  }

  // ondestroy
  ngOnDestroy() {
    // Desuscribimos el evento de router
    this.routerSubscription.unsubscribe();
  }

  private initializeSession() {

    this.wizardService.workoutModesAvailable = this.route.snapshot.data.workoutModesAvailable;
    this.wizardService.workoutModesAvailable = this.wizardService.workoutModesAvailable.slice(0, 3); // Eliminamos EMOM
    this.wizardService.moodsAvailable = this.route.snapshot.data.moodsAvailable;
    this.wizardService.playlistsAvailable = this.route.snapshot.data.playlistsAvailable;

    this.moodsAvailable = this.route.snapshot.data.moodsAvailable;
    

  }

  public workoutModeSelect(mode: IWorkoutMode) {
    this.wizardService.wizardData.session.workoutMode = mode;
    this.wizardService.goToNextStep();
  }

  public dataChanged($event) {
    this.wizardService.calculateTotalTime();
  }


  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-dialog-centered' }));
  }

  public closeModal() {
    this.modalRef.hide();
  }

  public goToNextStep() {
    this.wizardService.goToNextStep();
    this.doStepProcedures();
  }

  public goToLastStep() {
    this.wizardService.goToLastStep();
  }

  public goToStep(step: string) {
    this.wizardService.goToStep(step);
    this.doStepProcedures();
  }

  public doStepProcedures() {
    switch (this.wizardService.currentWizardStep.key) {
      case "cardioStationToggle":
        this.cardioModalComponent.open(); break;
      case "circuitRepeatToggle":
        this.circuitRepeatModalComponent.open(); break;
      case "warmupCooldownConfig":
        this.warmupCooldownModalComponent.open(); break;
      case "finisherToggle":
        this.finisherModalComponent.open(); break;
      case "finisherConfig":
        if (!this.wizardService.wizardData.finisher.finisher) {
          this.finisherSelectModal.open();
        }
        break;
      case "ambienceToggle":
        this.ambienceSelectModalComponent.open(); break;
      case "heartrateToggle":
        this.heartrateModalComponent.open(); break;
    }
  }

  public finisherClicked(station: any) {
    if (this.wizardService.wizardData.finisher.finisher) {
      this.finisherOptionsMenu = !this.finisherOptionsMenu;
    }
  }

  public openSelectFinisherModal() {
    this.finisherSelectModal.open();
  }

  public clearFinisherExercise() {
    this.wizardService.wizardData.finisher.finisher = undefined;
  }

  public sendWizard() {
    this.nameModalComponent.open();
  }

  loadData() {
    if (this.route.snapshot.data.timeline !== 'new') {
      this.timeline = this.route.snapshot.data.timeline;

      if (this.timeline.session.id !== 0) {
        this.sessionService.getSession(this.timeline.session.id).subscribe(
          result => {
            this.session = result;

            // if (this.timeline.cardioExercises.length > 0) {
            //   this.cardioExercises = this.timeline.cardioExercises;
            //   this.sortExercises(this.cardioExercises);
            // }
            // this.sortExercises(this.sessionExercisesZone1);
            // this.sortExercises(this.sessionExercisesZone2);
            this.wizardService.initializeData(this.timeline, this.session);

            if(this.timeline.status !== 0){
              this.sessionCopyModalComponent.open();
            }
          });
      }
      if(this.timeline.cardioExercises.length > 0){
        this.timeline.cardioExercises.forEach(cardioExercise => {
          this.exerciseService.getExercise(cardioExercise.exercise).subscribe(exercise => {
            cardioExercise.exercise = exercise;
            this.wizardService.initializeData(this.timeline, this.session);
          });
          
        });
      }

      if (this.timeline.finisher.id !== 0) {
        this.sessionService.getSession(this.timeline.finisher.id).subscribe(
          result => {
            this.timeline.finisher = result;
            // this.finisherExercises = this.finisher.exercises;
            // this.sortExercises(this.finisherExercises);
            this.wizardService.initializeData(this.timeline, this.session);
          });
      }
    }else{
      this.wizardService.initializeData(this.timeline, this.session);
    }


  }


}
