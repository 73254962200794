import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { IProfile } from '../interfaces/i-profile';
import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

@Injectable()
export class ProfilesService {

  private urlGetProfiles = CONFIG.serverUrl + ENDPOINTS.bootiq.profiles.get;
  private urlPutProfile = CONFIG.serverUrl + ENDPOINTS.bootiq.profiles.put;
  private urlPostProfile = CONFIG.serverUrl + ENDPOINTS.bootiq.profiles.post;
  private urlDeleteProfile = CONFIG.serverUrl + ENDPOINTS.bootiq.profiles.delete;

  constructor(public http: HttpClient) { }

  public getProfiles(): Observable<any> {
    return this.http.get(this.urlGetProfiles);
  }

  public putProfile(profile: IProfile): Observable<any> {
    const data = profile;
    return this.http.put(this.urlPutProfile, data);
  }

  public postProfile(profile: IProfile): Observable<any> {
    const data = profile;
    return this.http.post(this.urlPostProfile, data);
  }

  public deleteProfile(id: number): Observable<any> {
    return this.http.delete(this.urlDeleteProfile.replace(':id', id.toString()));
  }


}
