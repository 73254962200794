import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStation } from '../interfaces/models/i-station';
import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";
import { ActivatedRoute, Router } from '@angular/router';

export interface WizardStep {
  id: number;
  key: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  DEFAULT_WORK_TIME: number = 40;
  MOOD_OFF_ID: number = 11;
  DEFAULT_REST_TIME: number = 10;
  DEFAULT_EXTRAREST_TIME: number = 60;
  DEFAULT_ROUNDS: number = 3;

  public DEFAULT_MOOD = {
    id: -1    ,
    moodName: "Select",
    name: "SELECT",
  }
  public DEFAULT_PLAYLIST = {
    id: -1    ,
    playlistName: "Select",
    name: "SELECT",
  }

  public wizardData;

  public workoutModesAvailable;
  public moodsAvailable;
  public playlistsAvailable;

  // public stations: IStation[] = [
  //   { id: 6, name: "Combo", selectedExercise: null, location: 0 },
  //   { id: 7, name: "Sprint", selectedExercise: null, location: 0 },
  //   { id: 1, name: "Web", selectedExercise: null, location: 0 },
  //   { id: 2, name: "Hoops", selectedExercise: null, location: 0 },
  //   { id: 3, name: "Slides", selectedExercise: null, location: 0 },
  //   { id: 4, name: "Squares", selectedExercise: null, location: 0 },
  //   { id: 5, name: "Ladder", selectedExercise: null, location: 0 },
  //   { id: 10, name: "TRX", selectedExercise: null, location: 0 },
  // ];


  public cardioStations;

  public wizardSteps: WizardStep[] = [
    { id: 10, key: 'trainingTypeSelect', name: "Training type selection" },
    //{ key: 'exerciseSelect', name: "Exercises selection" },
    //{ key: 'sessionTimesConfig', name: "Session times configuration" },
    { id: 20, key: 'sessionConfig', name: "Session configuration" },
    { id: 30, key: 'cardioStationToggle', name: "Cardio Station Yes/No" },
    { id: 40, key: 'cardioStationConfig', name: "Cardio Station Configuration" },
    { id: 50, key: 'cardioStationExercisesSelect', name: "Cardio Station Exercises selection" },
    { id: 60, key: 'circuitRepeatToggle', name: "Circuit repeat configuration" },
    { id: 65, key: 'warmupCooldownConfig', name: "Warmup/Cooldown configuration" },
    { id: 70, key: 'finisherToggle', name: "Finisher modal" },
    { id: 80, key: 'finisherConfig', name: "Finisher configuration" },
    { id: 90, key: 'ambienceToggle', name: "Ambience selection" },
    // { id: 100, key: 'ambienceConfig', name: "Ambience configuration" },
    // { id: 110, key: 'musicSelection', name: "Music selection" },
    // { id: 120, key: 'moodSelection', name: "Mood selection" },
    { id: 130, key: 'heartrateToggle', name: "Heart Rate Yes/No" },
    { id: 200, key: 'finalBriefing', name: "Final Briefing" },
    { id: 300, key: 'end', name: "End" },
  ]

  public currentWizardStep: WizardStep;
  public lastWizardStep: WizardStep;

  public wizardSentStatus;

  public sendToValidation: boolean = false;

  timeline: any;
  session: any;

  constructor(public http: HttpClient,
    private router: Router,
    private route: ActivatedRoute) {

    // this.initializeData();
  }

  public initializeData(timeline: any = {}, session: any = {}) {
    this.timeline = timeline;
    this.session = session;

    this.wizardData = null;
    this.cardioStations = 0;
    this.currentWizardStep = this.wizardSteps[0];
    this.lastWizardStep = this.currentWizardStep;
    this.wizardSentStatus = null;

    if (timeline.id && session.id && this.route.snapshot.data.timeline !== 'new') {
      this.wizardData = {

        session: {
          id: session.id,
          rounds: session.rounds.length || 2,
          workTime: session.rounds[0].work || 45,
          restTime: session.rounds[0].rest || 15,
          extraRestTime: session.rounds[0].extraRest || 40,
          totalTime: 800,

          workoutMode: session.workoutMode || {
            id: 1,
            name: "Round"
          },

          stations: [
            { id: 6, name: "Combo", selectedExercise: this.getStationExercise(6, session), order: 1, zone: 1, optionsMenu: false, location: 0 },
            { id: 7, name: "Sprint", selectedExercise: this.getStationExercise(7, session), order: 2, zone: 1, optionsMenu: false, location: 0 },
            { id: 1, name: "Web", selectedExercise: this.getStationExercise(1, session), order: 3, zone: 1, optionsMenu: false, location: 0 },
            { id: 2, name: "Hoops", selectedExercise: this.getStationExercise(2, session), order: 4, zone: 1, optionsMenu: false, location: 0 },
            { id: 3, name: "Slides", selectedExercise: this.getStationExercise(3, session), order: 5, zone: 1, optionsMenu: false, location: 0 },
            { id: 4, name: "Squares", selectedExercise: this.getStationExercise(4, session), order: 6, zone: 1, optionsMenu: false, location: 0 },
            { id: 5, name: "Ladder", selectedExercise: this.getStationExercise(5, session), order: 7, zone: 1, optionsMenu: false, location: 0 },
            { id: 10, name: "TRX", selectedExercise: this.getStationExercise(10, session), order: 8, zone: 1, optionsMenu: false, location: 0 },
          ],
        },

        finisher: {
          rounds: timeline.finisher?.rounds?.length || 1,
          workTime: this.getExerciseField('workTime', timeline.finisher) || 45,
          restTime: 20,
          extraRestTime: 30,
          totalTime: 0,
          finisher: timeline.finisher || null
        },
        cardioStations: 0,
        cardioExercises: timeline.cardioExercises,

        timeBetweenFunctionalAndCardioStations: timeline.timeBetweenFunctionalAndCardio || 45,
        circuitRepeatTimes: timeline.circuitRepetitions || 1,
        timeBetweenCircuits: timeline.timeBetweenCircuits || 45,

        ambience: {
          id: session.program?.id || null,
          customPlaylistWork: this.playlistsAvailable.find(playlist => playlist.id === (this.session.playlists.find(playlist2 => playlist2.mode === 3)?.playlist || -1)) || this.DEFAULT_PLAYLIST,
          customPlaylistRest: this.playlistsAvailable.find(playlist => playlist.id === (this.session.playlists.find(playlist2 => playlist2.mode === 4)?.playlist || -1)) || this.DEFAULT_PLAYLIST,
          customMoodWork: this.moodsAvailable.find(mood => mood.id === this.session.moods.find(mood => mood.mode === 3).mood) || this.DEFAULT_MOOD,
          customMoodRest: this.moodsAvailable.find(mood => mood.id === this.session.moods.find(mood => mood.mode === 4).mood) || this.DEFAULT_MOOD,
        },

        warmupTime: timeline.warmupTime || 0,
        cooldownTime: timeline.cooldownTime || 0,

        heartRate: timeline.session.useHR,
        name: timeline.name,
        id: timeline.id,
        status: timeline.status
      }
      this.cardioConfigSetup();
      this.goToStep('finalBriefing');

    } else {
      this.wizardData = {

        session: {
          rounds: 2,
          workTime: 45,
          restTime: 15,
          extraRestTime: 40,
          totalTime: 800,

          workoutMode: {
            id: 1,
            name: "Round"
          },

          stations: [
            { id: 6, name: "Combo", selectedExercise: null, order: 1, zone: 1, optionsMenu: false, location: 0 },
            { id: 7, name: "Sprint", selectedExercise: null, order: 2, zone: 1, optionsMenu: false, location: 0 },
            { id: 1, name: "Web", selectedExercise: null, order: 3, zone: 1, optionsMenu: false, location: 0 },
            { id: 2, name: "Hoops", selectedExercise: null, order: 4, zone: 1, optionsMenu: false, location: 0 },
            { id: 3, name: "Slides", selectedExercise: null, order: 5, zone: 1, optionsMenu: false, location: 0 },
            { id: 4, name: "Squares", selectedExercise: null, order: 6, zone: 1, optionsMenu: false, location: 0 },
            { id: 5, name: "Ladder", selectedExercise: null, order: 7, zone: 1, optionsMenu: false, location: 0 },
            { id: 10, name: "TRX", selectedExercise: null, order: 8, zone: 1, optionsMenu: false, location: 0 },
          ],
        },

        finisher: {
          rounds: 1,
          workTime: 45,
          restTime: 20,
          extraRestTime: 30,
          totalTime: 0,
          finisher: null
        },
        cardioStations: 0,
        cardioExercises: [],

        timeBetweenFunctionalAndCardioStations: 45,
        circuitRepeatTimes: 1,
        timeBetweenCircuits: 45,

        ambience: {
          id: null,
          customPlaylistWork: this.DEFAULT_PLAYLIST,
          customPlaylistRest: this.DEFAULT_PLAYLIST,
          customMoodWork: this.DEFAULT_MOOD,
          customMoodRest: this.DEFAULT_MOOD,
        },

        warmupTime: 0,
        cooldownTime: 0,

        heartRate: true,
        name: '',
        status: 0
      }
    }
  }

  private getStep(): WizardStep {
    return this.wizardSteps.find(step => step.key === this.currentWizardStep.key);
  }
  private getStepIndex(): number {
    return this.wizardSteps.findIndex(step => step.key === this.currentWizardStep.key);
  }

  public goToNextStep() {
    let currentIndex: number = this.getStepIndex();
    currentIndex++;
    this.currentWizardStep = currentIndex < this.wizardSteps.length ? this.wizardSteps[currentIndex] : this.wizardSteps.slice(-1)[0];
    this.lastWizardStep = this.lastWizardStep.id < this.currentWizardStep.id ? this.currentWizardStep : this.lastWizardStep;
  }
  public goToStep(newStep: string) {
    this.currentWizardStep = this.wizardSteps.find(step => step.key === newStep);
    this.lastWizardStep = this.lastWizardStep.id < this.currentWizardStep.id ? this.currentWizardStep : this.lastWizardStep;
  }

  public goToLastStep() {
    this.goToStep(this.lastWizardStep.key);
  }


  public getTotalExercises(location: number = 0) {
    return this.wizardData.session.stations.filter((station) => station.selectedExercise !== null && station.location == location).length;
  }


  public calculateTotalTime() {

    // Número de ejercicios N
    // Tiempo de work W
    // Tiempo de rest R
    // Tiempo de Extra Rest EX
    // Tiempo Funcional TF
    // Time Between Cardio TBC
    // Número de circuitos C
    // Time BetWeen Circuits TBCi

    // Tiempo funcional
    // Rondas (suponiendo que todas las rondas tienen los mismos tiempos)
    // (N x W + (N-1) x R) x nºRondas + (nºRondas – 1 ) x EX = TF
    // Sets (suponiendo que todas los sets tienen los mismos tiempos)
    // (nºSets x W + (nºSets-1) x R) x N + (N – 1 ) x EX  = TF
    // SuperSets (suponiendo que todas las superseries tienen los mismos tiempos)
    // ((nºSuperSets x 2) x W) + (((nºSuperSets x 2) -1) x R) x (N/2) + (N/2  – 1 ) x EX = TF

    // Tiempo Funcional + cardio = TF&C
    // TF x (nº zonas funcionales + nºzonas cardio) + TBC x (nº zonas funcionales + nºzonas cardio - 1)  = TF&C

    // (Funcional + cardio) en circuito = Tiempo Total = TT
    // C  x TF&C + (C - 1) x TBCi = TT


    let totalTime = 0;

    switch (this.wizardData.session.workoutMode.id) {
      // Rounds
      case 1:
        totalTime = (this.getTotalExercises(0) * this.wizardData.session.workTime + (this.getTotalExercises(0) - 1) * this.wizardData.session.restTime) * this.wizardData.session.rounds + (this.wizardData.session.rounds - 1) * this.wizardData.session.extraRestTime;
        break;
      // Sets
      case 2:
        totalTime = (this.wizardData.session.rounds * this.wizardData.session.workTime + (this.wizardData.session.rounds - 1) * this.wizardData.session.restTime) * this.getTotalExercises(0) + (this.getTotalExercises(0) - 1) * this.wizardData.session.extraRestTime;
        break;
      // SuperSets
      case 3:
        totalTime = ((this.wizardData.session.rounds * 2) * this.wizardData.session.workTime) + (((this.wizardData.session.rounds * 2) - 1) * this.wizardData.session.restTime) * (this.getTotalExercises(0) / 2) + (this.getTotalExercises(0) / 2 - 1) * this.wizardData.session.extraRestTime;
        break;
    }

    // Si tiene zonas cardio
    const cardioZones = this.wizardData.session.stations.filter(station => station.location == 1).length;
    if (cardioZones > 0) {
      totalTime = totalTime * (1 + cardioZones) + this.wizardData.timeBetweenFunctionalAndCardioStations * (1 + cardioZones - 1);
    }

    // Si es circuito
    if (this.wizardData.circuitRepeatTimes > 1) {
      totalTime = this.wizardData.circuitRepeatTimes * totalTime + (this.wizardData.circuitRepeatTimes - 1) * this.wizardData.timeBetweenCircuits;
    }

    return totalTime;
  }

  public cardioConfigSetup() {
    if (this.getCardioStations()) {
      this.wizardData.cardioStations = this.getCardioStations().length;
    }
    //this.wizardData.cardioExercises = this.getCardioExercises();

    for (let i = 0; i < this.wizardData.cardioStations; i++) {
      const newCardioStation = {
        id: 0,
        location: 1,
        name: `Cardio ${i + 1}`,
        selectedExercise: this.wizardData.cardioExercises.length >i ? this.wizardData.cardioExercises[i].exercise : null,
        order: i + 1,
        zone: 1,
        optionsMenu: false
      };
      this.wizardData.session.stations.push(newCardioStation);
    }
  }

  public cardioChange() {
    //this.removeCardioStations();
    const difference = this.wizardData.cardioStations - this.wizardData.session.stations.filter(station => station.location === 1).length;
    if (difference > 0) {
      //for(let i = 0; i < difference; i++){
      const i = this.wizardData.session.stations.filter(station => station.location === 1).length;
      const newCardioStation = {
        id: 0,
        location: 1,
        name: `Cardio ${i + 1}`,
        selectedExercise: null,
        order: i + 1,
        zone: 1,
        optionsMenu: false
      };
      this.wizardData.session.stations.push(newCardioStation);
      //}
    }
    if (difference < 0) {
      this.wizardData.session.stations = this.wizardData.session.stations.slice(0, -1);
    }
    // for (let i = 0; i < this.timeline.cardioExercises.length; i++) {
    //   const newCardioStation = {
    //     id: 0,
    //     location: 1,
    //     name: `Cardio ${i + 1}`,
    //     selectedExercise: null,
    //     order: i + 1,
    //     zone: 1,
    //     optionsMenu: false
    //   };
    //   this.wizardData.session.stations.push(newCardioStation);
    // }
  }

  public removeCardioStations() {
    this.wizardData.session.stations = this.wizardData.session.stations.filter(station => station.location !== 1);
  }

  public getCardioStations() {
    return this.timeline.cardioExercises;
  }

  // Get cardio exercises as an array of exercises of each cardio station
  public getCardioExercises() {
    return this.timeline.cardioExercises.map(station => station.exercise);
  }

  public sendWizardData() {
    let dataToSend = JSON.parse(JSON.stringify(this.wizardData));

    // Preparamos los datos para enviar
    if (dataToSend.finisher.finisher) {
      dataToSend.finisher = dataToSend.finisher.finisher.id;
    } else {
      dataToSend.finisher = 0;
    }

    // Asignar stations.selectedExercise.id al campo session.stations.selectedExercise de cada estacion
    dataToSend.session.stations.forEach(station => {
      if (station.selectedExercise) {
        station.selectedExercise = station.selectedExercise.id;
      }
    });

    // Añadimos selectedExercises de estaciones con location 1 a wizardData.cardioExercises
    dataToSend.cardioExercises = [];
    let index = 1;
    dataToSend.session.stations.forEach(station => {
      if (station.location == 1 && station.selectedExercise) {
        dataToSend.cardioExercises.push(
          {
            exercise: station.selectedExercise,
            cardioIndex: index
          });
        index++;
      }
    });

    // Eliminamos estaciones con location 1
    dataToSend.session.stations = dataToSend.session.stations.filter(station => station.location != 1);

    // Eliminamos estaciones sin selectedExercise
    dataToSend.session.stations = dataToSend.session.stations.filter(station => station.selectedExercise != null);

    // Asignamos incremental desde 1 al order de todas las estaciones
    let orderIndex = 1;
    dataToSend.session.stations.forEach(station => {
      station.order = orderIndex;
      orderIndex++;
    });

    // Quitamos optionsMenu y location de todas las estaciones    
    dataToSend.session.stations.forEach(station => {
      delete station.optionsMenu;
      delete station.location;
    });

    // Asignamos id plano a moods y playlists
    dataToSend.ambience.customMoodWork = dataToSend.ambience.customMoodWork.changed ? dataToSend.ambience.customMoodWork.id : -1;
    dataToSend.ambience.customMoodRest = dataToSend.ambience.customMoodRest.changed ? dataToSend.ambience.customMoodRest.id : -1;
    dataToSend.ambience.customPlaylistWork = dataToSend.ambience.customPlaylistWork.changed ? dataToSend.ambience.customPlaylistWork.id : -1;
    dataToSend.ambience.customPlaylistRest = dataToSend.ambience.customPlaylistRest.changed ? dataToSend.ambience.customPlaylistRest.id : -1;


    const urlPostWizard = CONFIG.serverUrl + ENDPOINTS.wizard.post;
    this.wizardSentStatus = 1;

    // Solo se asigna el id si es una edicion, permitida si el timeline es SAVED o REJECTED
    if (dataToSend.status === 0 || dataToSend.status === 3) {
      dataToSend.id = this.wizardData.id;
    }else{
      dataToSend.id = 0;
    }

    if (this.sendToValidation) {
      dataToSend.status = 2;
    } else {
      dataToSend.status = 0;
    }

    if (dataToSend.id && this.route.snapshot.data.timeline !== 'new') {
      return this.http.put(urlPostWizard, dataToSend).subscribe(result => {
        this.wizardSentStatus = 2;
      });
    } else {
      return this.http.post(urlPostWizard, dataToSend).subscribe(result => {
        this.wizardSentStatus = 2;
      });
    }
  }

  public resetWizard() {
    // Reload page
    window.location.reload();
  }

  public exitWizard() {
    this.router.navigate(['/my-timelines']);
  }

  public isBackMode() {
    return this.currentWizardStep.id < this.lastWizardStep.id;
  }

  public canContinue() {
    // Si la session no tiene 2 ejercicios como minimo, devuelve falso
    if (this.getTotalExercises(0) < 1) {
      return false;
    }

    // Si el workoutmode es superset y la session no tiene numero de ejercicios pares, devuelve false
    if (this.wizardData.session.workoutMode.id == 3 && this.getTotalExercises(0) % 2 != 0) {
      return false;
    }

    return true;
  }

  private getStationExercise(stationId: number, session: any) {
    return session.gymExercises.find(exercise => exercise.idStation == stationId);
  }

  private getExerciseField(field: string, session: any) {
    if (session.exercises) {
      return session.exercises[0][field];
    } else {
      return null;
    }
  }


}
