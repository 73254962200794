import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WizardService } from '../../../services/wizard.service';

import { CONFIG } from "../../../../assets/config";

import { IStation } from '../../../interfaces/models/i-station';
import { ExerciseSelectModalComponent } from '../exercise-select-modal/exercise-select-modal.component';

@Component({
  selector: 'exercise-grid',
  templateUrl: './exercise-grid.component.html',
  styleUrls: ['./exercise-grid.component.scss']
})
export class ExerciseGridComponent implements OnInit {

  exercisesMediaURL: string = CONFIG.exercisesMediaURL;

  @Input() stations;

  @ViewChild(ExerciseSelectModalComponent) exerciseSelectModal;

  modalRef: BsModalRef;

  constructor(private modalService: BsModalService,
    private wizardService: WizardService) { }

  ngOnInit(): void {
  }


  public exerciseClicked(station: any) {
    if(station.optionsMenu){
      station.optionsMenu = false;
    }else{
      this.closeAllOptionsMenus();
      station.optionsMenu = true;
    }
  }
  public openSelectExerciseModal(station: any, $event) {
    this.exerciseSelectModal.open(station);
    this.closeAllOptionsMenus();
    $event.stopPropagation();
  }

  public closeAllOptionsMenus(){
    this.wizardService.wizardData.session.stations.forEach(station => {
      station.optionsMenu = false;
    });
  }
  
  // Eliminar los ejercicios de todas las estaciones
  

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-dialog-centered' }));
  }

  public closeModal() {
    this.modalRef.hide();
  }

  public clearStationExercise(station){
    station.selectedExercise = null;
    station.optionsMenu = false;
  }

  public getStations(location: number){
    return this.wizardService.wizardData.session.stations.filter(station => station?.location == location);
  }

  getCardioStationSize(){
    switch(this.getStations(1).length){
      case 1: return 50; break;
      case 2: return 50; break;
      case 3: return 50; break;
      case 4: return 50; break;
    }
  }
  getCardioStationLayout(){
    switch(this.getStations(1).length){
      case 1: return "column"; break;
      case 2: return "column"; break;
      case 3: return "row wrap"; break;
      case 4: return "row wrap"; break;
    }
  }
   
    
}
