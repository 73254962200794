import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WizardService } from '../../../services/wizard.service';

@Component({
  selector: 'name-modal',
  templateUrl: './name-modal.component.html',
  styleUrls: ['./name-modal.component.scss']
})
export class NameModalComponent implements OnInit {

  @ViewChild("nameModal") nameModal;

  modalRef: BsModalRef;
  
  constructor(private modalService: BsModalService,
    public wizardService: WizardService) { }

  ngOnInit(): void {
  }

  open(){
    this.openModal();
  }

  public openModal() {
    this.modalRef = this.modalService.show(this.nameModal, Object.assign({}, { class: 'modal-dialog-centered modal-md wizard-modal' }));
  }
  public closeModal() {
    this.modalRef.hide();
  }

  public sendWizardData($event){
    this.wizardService.sendWizardData();
    //this.closeModal();
  }


}
