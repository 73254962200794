import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { RolesService } from '../roles.service';
import { of } from "rxjs";

@Injectable()
export class RoleListResolver implements Resolve<Observable<string>> {

    constructor(private rolesService: RolesService) { }

    resolve() {
        return this.rolesService.getRoles().catch(() => {
            return of('error');
        });
    }

}

