<div class="number-display" fxLayout="column" fxLayoutAlign="stretch stretch" fxFlex [ngClass]="{'no-background': !background, 'font-bold': bigText}">
    <h5 fxFlex="40" fxLayoutAlign="center center">
        {{title}}
    </h5>
    <div class="number-container" fxFlex="60" fxLayoutAlign="center center">        
        <div class="number-display-text" [ngClass]="{'number-display-big': bigText}">
            <span *ngIf="!isTime">{{number}}</span>
            <span *ngIf="isTime">{{secondsToMinutes(number)}}</span>
        </div>
    </div>
</div>