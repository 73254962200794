import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { IRole } from '../interfaces/i-role';
import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

@Injectable()
export class RolesService {

  private urlGetRoles = CONFIG.serverUrl + ENDPOINTS.bootiq.roles.get; 

  constructor(public http: HttpClient) { }

  public getRoles(): Observable<any> {
    return this.http.get(this.urlGetRoles);
  }

}
