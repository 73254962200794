import { ITimelineValidation } from './../interfaces/models/i-timeline-validation';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";


import { QuestionBase } from "../interfaces/models/i-question-base";
import { TextboxQuestion } from "../interfaces/models/i-question-textbox";
import { DropdownQuestion } from "../interfaces/models/i-question-dropdown";

import { ITimelineWelcome, ITimelineExplanation, ITimelineWarmUp, ITimelinePositioningGt, ITimelinePositioningFinisher, ICountdown, ITimelineResultsGt, ITimelineResultsFinisher, ITimelineCooldown, ITimelineEnding, ITimelineSession, ITimeline, ITimelineProgram } from "../interfaces/models/i-timeline";
import { IPlaylist } from "../interfaces/models/i-playlist";
import { IMood } from "../interfaces/models/i-mood";
import { ISession } from "../interfaces/models/i-session";

import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class TimelineService {

  private urlGetTimeline: string;
  private urlGetTimelines: string;
  private urlGetTimelineCountdowns: string;
  private urlGetTimelineWelcomes: string;
  private urlGetTimelineExplanations: string;
  private urlGetTimelineWarmUps: string;
  private urlGetTimelinePositionings: string;
  private urlGetTimelineResults: string;
  private urlGetTimelineCooldowns: string;
  private urlGetTimelineEndings: string;
  private urlGetTimelineFinishers: string;
  private urlPostTimeline: string;
  private urlPutTimeline: string;
  private urlGetTimelineByProgram: string;
  private urlPutTimelineProgram: string;
  private urlGetProgramBasicTimelines: string;
  private urlPutProgramBasicTimelines: string;
  
  private urlGetPendingTimelines: string;
  private urlGetMyTimelines: string;
  private urlPostTimelineValidation: string;
  private urlGetTimelineLogs: string;

  constructor(private http: HttpClient) {
    this.urlGetTimeline = CONFIG.serverUrl + ENDPOINTS.timeline.get;
    this.urlGetTimelines = CONFIG.serverUrl + ENDPOINTS.timelines.get;
    this.urlGetTimelineCountdowns = CONFIG.serverUrl + ENDPOINTS.timelineCountdowns.get;
    this.urlGetTimelineWelcomes = CONFIG.serverUrl + ENDPOINTS.timelineWelcomes.get;
    this.urlGetTimelineExplanations = CONFIG.serverUrl + ENDPOINTS.timelineExplanations.get;
    this.urlGetTimelineWarmUps = CONFIG.serverUrl + ENDPOINTS.timelineWarmUps.get;
    this.urlGetTimelinePositionings = CONFIG.serverUrl + ENDPOINTS.timelinePositionings.get;
    this.urlGetTimelineResults = CONFIG.serverUrl + ENDPOINTS.timelineResults.get;
    this.urlGetTimelineCooldowns = CONFIG.serverUrl + ENDPOINTS.timelineCooldowns.get;
    this.urlGetTimelineEndings = CONFIG.serverUrl + ENDPOINTS.timelineEndings.get;
    this.urlGetTimelineFinishers = CONFIG.serverUrl + ENDPOINTS.finishers.get;
    this.urlPostTimeline = CONFIG.serverUrl + ENDPOINTS.timeline.post;
    this.urlPutTimeline = CONFIG.serverUrl + ENDPOINTS.timeline.put;
    this.urlGetTimelineByProgram = CONFIG.serverUrl + ENDPOINTS.timelines.getByProgram;
    this.urlPutTimelineProgram = CONFIG.serverUrl + ENDPOINTS.timelines.putProgram;
    this.urlGetProgramBasicTimelines = CONFIG.serverUrl + ENDPOINTS.timelines.getBasic;
    this.urlPutProgramBasicTimelines = CONFIG.serverUrl + ENDPOINTS.timelines.putBasic;

    this.urlGetPendingTimelines = CONFIG.serverUrl + ENDPOINTS.timelineValidation.getPending;  
    this.urlGetMyTimelines = CONFIG.serverUrl + ENDPOINTS.timelineValidation.getMyTimelines;
    this.urlPostTimelineValidation = CONFIG.serverUrl + ENDPOINTS.timelineValidation.postValidation;  
    this.urlGetTimelineLogs = CONFIG.serverUrl + ENDPOINTS.timelineValidation.getTimelineLogs;  
  }

  @Cacheable()
  public getTimelines(): Observable<any> {
    return this.http.get(this.urlGetTimelines);
  }

  public getTimeline(timeline: number): Observable<any> {
    this.urlGetTimeline = CONFIG.serverUrl + ENDPOINTS.timeline.get
    this.urlGetTimeline = this.urlGetTimeline.replace(":id", timeline.toString());
    return this.http.get(this.urlGetTimeline);
  }
  @Cacheable()
  public getWelcomes(): Observable<any> {
    return this.http.get(this.urlGetTimelineWelcomes);
  }
  @Cacheable()
  public getWarmUps(): Observable<any> {
    return this.http.get(this.urlGetTimelineWarmUps);
  }
  @Cacheable()
  public getPositionings(): Observable<any> {
    return this.http.get(this.urlGetTimelinePositionings);
  }
  @Cacheable()
  public getResults(): Observable<any> {
    return this.http.get(this.urlGetTimelineResults);
  }
  @Cacheable()
  public getCooldowns(): Observable<any> {
    return this.http.get(this.urlGetTimelineCooldowns);
  }
  @Cacheable()
  public getEndings(): Observable<any> {
    return this.http.get(this.urlGetTimelineEndings);
  }
  @Cacheable()
  public getExplanations(): Observable<any> {
    return this.http.get(this.urlGetTimelineExplanations);
  }
  @Cacheable()
  public getCountdowns(): Observable<any> {
    return this.http.get(this.urlGetTimelineCountdowns);
  }
  @Cacheable()
  public getFinishers(): Observable<any> {
    return this.http.get(this.urlGetTimelineFinishers);
  }

  public putTimeline(timeline: ITimeline): Observable<any> {
    const data = timeline;
    return this.http.put(this.urlPutTimeline, data);
  }

  public postTimeline(timeline: ITimeline): Observable<any> {
    const data = timeline;
    return this.http.post(this.urlPostTimeline, data);
  }

  public postTimelineValidation(timelineValidation: ITimelineValidation): Observable<any> {
    const data = timelineValidation;
    return this.http.post(this.urlPostTimelineValidation, data);
  }

  @Cacheable()
  public getTimelineByProgram(program: number): Observable<any> {
    this.urlGetTimelineByProgram = CONFIG.serverUrl + ENDPOINTS.timelines.getByProgram;
    this.urlGetTimelineByProgram = this.urlGetTimelineByProgram.replace(":id", program.toString());
    return this.http.get(this.urlGetTimelineByProgram);
  }

  public putProgramTimelines(program: ITimelineProgram): Observable<any>{
    this.urlPutTimelineProgram = CONFIG.serverUrl + ENDPOINTS.timelines.putProgram;
    return this.http.put(this.urlPutTimelineProgram, program);
  }

  public getProgramBasicTimelines(): Observable<any> { // Gets basic and no-basic, but in reduced format for programs section
    this.urlGetProgramBasicTimelines = CONFIG.serverUrl + ENDPOINTS.timelines.getBasic;    
    return this.http.get(this.urlGetProgramBasicTimelines);
  }

  public putProgramBasicTimelines(program: ITimelineProgram): Observable<any>{
    this.urlPutProgramBasicTimelines = CONFIG.serverUrl + ENDPOINTS.timelines.putBasic;
    return this.http.put(this.urlPutProgramBasicTimelines, program);
  }


  public getTimelineFinisherModes() {
    return [
      {
        id: 0,
        name: "Challenge"
      },
      {
        id: 1,
        name: "Time"
      },
      {
        id: 2,
        name: "Rounds"
      } 
    ];
  }



  public getWelcomeQuestions(welcome: ITimelineWelcome, playlist: IPlaylist[], moods: IMood[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: welcome.name,
        required: true,
        order: 1
      }),


      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === welcome.playListId) ? welcome.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === welcome.moodId) ? welcome.moodId : null
      }),

      new TextboxQuestion({
        key: 'welcomeTime',
        label: 'Time',
        value: welcome.welcomeTime,
        required: true,
        order: 4,
        type: 'number'
      })

    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getExplanationQuestions(explanation: ITimelineExplanation, playlist: IPlaylist[], moods: IMood[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: explanation.name,
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === explanation.playListId) ? explanation.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === explanation.moodId) ? explanation.moodId : null
      }),

      new TextboxQuestion({
        key: 'coverTime',
        label: 'Cover time',
        value: explanation.coverTime,
        required: true,
        order: 4,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'benefitsTime',
        label: 'Benefits time',
        value: explanation.benefitsTime,
        required: true,
        order: 5,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'summaryTime',
        label: 'Summary time',
        value: explanation.summaryTime,
        required: true,
        order: 6,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'duration',
        label: 'Duration time',
        value: explanation.duration,
        required: true,
        order: 6,
        type: 'number'
      }),

    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getWarmUpQuestions(warmUp: ITimelineWarmUp, playlist: IPlaylist[], moods: IMood[], countdowns: ICountdown[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: warmUp.name,
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === warmUp.playListId) ? warmUp.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === warmUp.moodId) ? warmUp.moodId : null
      }),

      new TextboxQuestion({
        key: 'duration',
        label: 'Duration time',
        value: warmUp.duration,
        required: true,
        order: 4,
        type: 'number'
      }),

      new DropdownQuestion({
        key: 'countdown',
        label: 'Countdown',
        options: this.getCountdownsObject(countdowns),
        required: true,
        order: 5,
        value: (warmUp.countdown) ? countdowns.some(x => x.id === warmUp.countdown.id) ? warmUp.countdown.id : null : null
      }),


    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getPositioningGtQuestions(positioning: ITimelinePositioningGt, playlist: IPlaylist[], moods: IMood[], countdowns: ICountdown[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: positioning.name,
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === positioning.playListId) ? positioning.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === positioning.moodId) ? positioning.moodId : null
      }),

      new DropdownQuestion({
        key: 'countdown',
        label: 'Countdown',
        options: this.getCountdownsObject(countdowns),
        required: true,
        order: 4,
        value: (positioning.countdown) ? countdowns.some(x => x.id === positioning.countdown.id) ? positioning.countdown.id : null : null
      }),

      new TextboxQuestion({
        key: 'stationTime',
        label: 'Station time',
        value: positioning.stationTime,
        required: true,
        order: 5,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'allStationsTime',
        label: 'All stations time',
        value: positioning.allStationsTime,
        required: true,
        order: 6,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'goToStationsTime',
        label: 'Go to stations time',
        value: positioning.goToStationsTime,
        required: true,
        order: 7,
        type: 'number'
      }),

    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getPositioningFinisherQuestions(positioning: ITimelinePositioningFinisher, playlist: IPlaylist[], moods: IMood[], countdowns: ICountdown[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: positioning.name,
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === positioning.playListId) ? positioning.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === positioning.moodId) ? positioning.moodId : null
      }),

      // new DropdownQuestion({
      //   key: 'countdown',
      //   label: 'Countdown',
      //   options: this.getCountdownsObject(countdowns),
      //   required: true,
      //   order: 4,
      //   value: (positioning.countdown) ? countdowns.some(x => x.id === positioning.countdown.id) ? positioning.countdown.id : null : null
      // }),

      new TextboxQuestion({
        key: 'stationTime',
        label: 'Station time',
        value: positioning.stationTime,
        required: true,
        order: 5,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'allStationsTime',
        label: 'All stations time',
        value: positioning.allStationsTime,
        required: true,
        order: 6,
        type: 'number'
      }),

      // new TextboxQuestion({
      //   key: 'goToStationsTime',
      //   label: 'Go to stations time',
      //   value: positioning.goToStationsTime,
      //   required: true,
      //   order: 7,
      //   type: 'number'
      // }),

    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getResultsGtQuestions(result: ITimelineResultsGt, playlist: IPlaylist[], moods: IMood[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: result.name,
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === result.playListId) ? result.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === result.moodId) ? result.moodId : null
      }),

      new TextboxQuestion({
        key: 'performanceTime',
        label: 'Performance time',
        value: result.performanceTime,
        required: true,
        order: 4,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'congratulationsTime',
        label: 'Congratulations time',
        value: result.congratulationsTime,
        required: true,
        order: 5,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'congratulationsMessage',
        label: 'Congratulations message',
        value: result.congratulationsMessage,
        required: true,
        order: 6
      }),
    ];

    return questions.sort((a, b) => a.order - b.order);
  }
  public getResultsFinisherQuestions(result: ITimelineResultsFinisher, playlist: IPlaylist[], moods: IMood[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: result.name,
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === result.playListId) ? result.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === result.moodId) ? result.moodId : null
      }),

      // new TextboxQuestion({
      //   key: 'performanceTime',
      //   label: 'Performance time',
      //   value: result.performanceTime,
      //   required: true,
      //   order: 4,
      //   type: 'number'
      // }),

      // new TextboxQuestion({
      //   key: 'congratulationsTime',
      //   label: 'Congratulations time',
      //   value: result.congratulationsTime,
      //   required: true,
      //   order: 5,
      //   type: 'number'
      // }),

      // new TextboxQuestion({
      //   key: 'congratulationsMessage',
      //   label: 'Congratulations message',
      //   value: result.congratulationsMessage,
      //   required: true,
      //   order: 6
      // }),

      new TextboxQuestion({
          key: 'duration',
          label: 'Duration',
          value: result.duration,
          required: true,
          order: 7,
          type: 'number'
        }),
    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getCooldownQuestions(cooldown: ITimelineCooldown, playlist: IPlaylist[], moods: IMood[], countdowns: ICountdown[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: cooldown.name,
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === cooldown.playListId) ? cooldown.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === cooldown.moodId) ? cooldown.moodId : null
      }),

      new TextboxQuestion({
        key: 'duration',
        label: 'Duration time',
        value: cooldown.duration,
        required: true,
        order: 4,
        type: 'number'
      }),

      new DropdownQuestion({
        key: 'countdown',
        label: 'Countdown',
        options: this.getCountdownsObject(countdowns),
        required: true,
        order: 5,
        value: (cooldown.countdown) ? countdowns.some(x => x.id === cooldown.countdown.id) ? cooldown.countdown.id : null : null
      }),


    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getEndingQuestions(ending: ITimelineEnding, playlist: IPlaylist[], moods: IMood[]) {

    let questions: QuestionBase<any>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: ending.name,
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'playListId',
        label: 'Playlist',
        options: this.getPlaylistObject(playlist),
        required: true,
        order: 2,
        value: playlist.some(x => x.idPlaylist === ending.playListId) ? ending.playListId : null
      }),

      new DropdownQuestion({
        key: 'moodId',
        label: 'Mood',
        options: this.getMoodsObject(moods),
        required: true,
        order: 3,
        value: moods.some(x => x.id === ending.moodId) ? ending.moodId : null
      }),

      new TextboxQuestion({
        key: 'duration',
        label: 'Duration time',
        value: ending.duration,
        required: true,
        order: 4,
        type: 'number'
      }),

      new TextboxQuestion({
        key: 'message',
        label: 'Message',
        value: ending.message,
        required: true,
        order: 5
      }),

    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getGtQuestions(session: ITimelineSession, sessionsList: ISession[]) {

    let questions: QuestionBase<any>[] = [

      new DropdownQuestion({
        key: 'id',
        label: 'Session',
        options: this.getSessionsObject(sessionsList),
        required: true,
        order: 2,
        value: sessionsList.some(x => x.id === session.id) ? session.id : null
      })

    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  public getFinisherQuestions(finisher: ITimelineSession, finishersList: ISession[]) {

    let questions: QuestionBase<any>[] = [

      new DropdownQuestion({
        key: 'id',
        label: 'Finisher',
        options: this.getSessionsObject(finishersList),
        required: true,
        order: 2,
        value: finishersList.some(x => x.id === finisher.id) ? finisher.id : null
      })

    ];

    return questions.sort((a, b) => a.order - b.order);
  }

  private getSessionsObject(sessions: ISession[]) {
    var keys = Object.keys(sessions);

    let sessionObject: { key: number, value: string, image: string }[] = [];

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];

      sessionObject.push({ key: sessions[key]["id"], value: sessions[key]["name"], image: '' });
    }

    return sessionObject;
  }

  private getPlaylistObject(playlists: IPlaylist[]) {
    var keys = Object.keys(playlists);

    let playlistObject: { key: number, value: string, image: string }[] = [];

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      playlistObject.push({ key: playlists[key]["idPlaylist"], value: playlists[key]["playlistName"], image: '' });
    }

    return playlistObject;
  }

  private getMoodsObject(moods: IMood[]) {
    var keys = Object.keys(moods);

    let moodObject: { key: number, value: string, image: string }[] = [];

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      moodObject.push({ key: moods[key]["id"], value: moods[key]["moodName"], image: this.getMoodImageName(moods[key]["id"]) });
    }

    return moodObject;
  }

  private getMoodImageName(id: string): string {
    let moodStr: string;
    if (id === null || id === "") {
      moodStr = "assets/moods/0";
    } else {
      moodStr = "assets/moods/" + id.toString();
    }
    moodStr += ".png"
    return moodStr;
  }

  private getCountdownsObject(countdowns: ICountdown[]) {
    var keys = Object.keys(countdowns);

    let moodObject: { key: number, value: string }[] = [];

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      moodObject.push({ key: countdowns[key]["id"], value: countdowns[key]["name"] });
    }

    return moodObject;
  }

  public getPendingTimelines(): Observable<any> {
    return this.http.get(this.urlGetPendingTimelines);
  }

  public getTimelineLogs(timelineId: number): Observable<any> {
    return this.http.get(this.urlGetTimelineLogs.replace(":id", timelineId.toString()));
  }

  public getMyTimelines(): Observable<any> {
    return this.http.get(this.urlGetMyTimelines);
  }
  
}
