<ng-template #nameModal>
    <div class="modal-content">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">Save session</h4>
            <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button> -->
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center" *ngIf="!wizardService.wizardSentStatus || wizardService.wizardSentStatus == -1">
            <div class="w-100 mb-1">
                <label>Enter session name:</label>
            </div>
            <input type="text" class="form-control text-font-sans" placeholder="Session name" [(ngModel)]="wizardService.wizardData.name">


            <div class="w-100 mt-2" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div fxLayout="column">
                    <mat-checkbox name="send-to-validation" [(ngModel)]="wizardService.sendToValidation">
                        Also send for validation
                    </mat-checkbox>
                </div>

                <div fxLayout="column" fxLayoutAlign="end center" (click)="sendWizardData()" fxLayoutGap="10px">
                    <div class="button-round" fxLayoutAlign="center center">
                        <mat-icon>check</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center" *ngIf="wizardService.wizardSentStatus==1">
            <h4>Your session is being sent...</h4>
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center" *ngIf="wizardService.wizardSentStatus==2">
            <h4>Your session has been successfully sent!</h4>
            <div fxLayout="row" fxLayoutAlign="end center" class="w-100 mt-3">
                <div fxLayout="row" fxLayoutAlign="end center" (click)="closeModal(); wizardService.exitWizard();" fxLayoutGap="10px">
                    <div class="button-round" fxLayoutAlign="center center">
                        <mat-icon>check</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>