import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import { CategoriesService } from '../../services/categories.service';
import { Category } from '../../interfaces/models/category';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';
import { MatCardTitleGroup } from '@angular/material/card';


@Component({
  selector: 'sessions-by-category',
  templateUrl: './sessions-by-category.component.html',
  styleUrls: ['./sessions-by-category.component.css']
})
export class SessionsByCategoryComponent implements OnInit {

  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;
  newMode: boolean;
  editMode: number;

  newCategory: Category;

  headers = {
    name: "Name",
    actions: "Actions"
  };

  categoryList: Category[];
  categoryListToShow: Category[];
  categoryTree: Category[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['name', 'sessions'];
  dataSource = new MatTableDataSource;

  timelines = [];


  constructor(public route: ActivatedRoute, private router: Router,
    private categoriesService: CategoriesService,
    public dialog: MatDialog,
    private notificationService: NotificationService) {

    this.categoryTree = this.route.snapshot.data.categories;
    this.categoryList = this.categoriesService.convertTreeToList(this.route.snapshot.data.categories);
    this.categoryListToShow = this.categoriesService.convertTreeToList(this.route.snapshot.data.categories);
    this.timelines = this.route.snapshot.data.timelines;
    // roles
    this.canModify = true;
    this.canCreate = true;
    this.canDelete = true;
  }


  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

  }


  ngAfterViewInit() {
    this.updateTableData();
  }


  updateTableData() {
    this.dataSource = new MatTableDataSource(this.categoriesService.sortListByName(this.categoryListToShow));
  }


  scrollTableToTop() {
    try {
      const matTable = document.getElementById('categoryTable');
      matTable.scrollTop = 0;
    } catch (err) {
      console.log(err);
    }
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public getTimelinesByCategory(category: Category) {    
    return this.timelines.filter(timeline => timeline.categories.includes(category.id));
  }

  
  canDeleteCategory(category: Category) {
    return (category.workouts == 0 || category.workouts == null) && (category.categories == null || category.categories.length == 0);
  }

  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }
}
