import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";
import { ENUMS } from "../../assets/enums";

import { IExercise } from "../interfaces/models/i-exercise";
import { IExerciseForm } from "../interfaces/models/i-exercise-form";
import { IGetExercisesResponse } from "../interfaces/http/i-get-exercises-response";
import { IPostExerciseResponse } from "../interfaces/http/i-post-exercise-response";
import { IPostFileResponse } from "../interfaces/http/i-post-file-response";
import { IGetExerciseResponse } from "../interfaces/http/i-get-exercise-response";
import { IExerciseType } from "../interfaces/models/i-exercise-type";
import { ISessionToken } from "../interfaces/models/i-session-token";
// import { ExerciseMapper } from "./mappers/exercise-mapper";
import { IExerciseDetail } from "../interfaces/models/i-exercise-detail";
import { IExerciseSkill } from "../interfaces/models/i-exercise-skill";
import { IExerciseMuscularGroup } from "../interfaces/models/i-exercise-muscular-group";
import { IExerciseFundamentalMovement } from "../interfaces/models/i-exercise-fundamental-movement";
import { IExerciseDiscipline } from "../interfaces/models/i-exercise-discipline";
import { IExercisesFilter } from "../interfaces/models/i-exercises-filter";

import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class ExerciseService {

  private urlGetExercises: string;
  private urlGetExercise: string;
  private urlPutExercise: string;
  private urlPostExercise: string;
  private urlGetSkills: string;
  private urlGetMuscularGroups: string;
  private urlGetFundamentalMovements: string;
  private urlGetDisciplines: string;

  private _exerciseTypes: IExerciseType[];
  private _exerciseSkills: IExerciseSkill[];
  private _muscularGroups: IExerciseMuscularGroup[];
  private _fundamentalMovements: IExerciseFundamentalMovement[];
  private _disciplines: IExerciseDiscipline[];

  public filterList: IExercisesFilter[] = [];
  public filterSearch: string = "";

  public exerciseTypes() {
    return this._exerciseTypes;
  }

  public exerciseSkills() {
    return this._exerciseSkills;
  }

  public exerciseMuscularGroups() {
    return this._muscularGroups;
  }

  public exerciseFundamentalMovements() {
    return this._fundamentalMovements;
  }

  public exerciseDisciplines() {
    return this._disciplines;
  }

  constructor(private http: HttpClient) {
    this.urlGetExercises = CONFIG.serverUrl + ENDPOINTS.bootiq.exercises.get;
    this.urlGetExercise = CONFIG.serverUrl + ENDPOINTS.exercise.get;
    this.urlPutExercise = CONFIG.serverUrl + ENDPOINTS.exercise.put;
    this.urlPostExercise = CONFIG.serverUrl + ENDPOINTS.exercise.post;
    this.urlGetSkills = CONFIG.serverUrl + ENDPOINTS.exerciseSkills.get;
    this.urlGetMuscularGroups = CONFIG.serverUrl + ENDPOINTS.muscularGroups.get;
    this.urlGetFundamentalMovements = CONFIG.serverUrl + ENDPOINTS.fundamentalMovements.get;
    this.urlGetDisciplines = CONFIG.serverUrl + ENDPOINTS.disciplines.get;
  }


  getExerciseTypes() {
    const url = CONFIG.serverUrl + ENDPOINTS.exerciseTypes.get;
    return new Promise((resolve, reject) => {
      if (this._exerciseTypes && this._exerciseTypes.length > 0) {
        if (CONFIG.traceLoads) {
          console.log("exerciseTypes load ok - cache");
        }
        return resolve(true);
      }

      this.http.get(url)
        .catch((error) => Observable.throw(error))
        .subscribe(
          (response: IExerciseType[]) => {
            if (CONFIG.traceLoads) {
              console.log("exercisetypes load ok");
            }
            this._exerciseTypes = response;
            resolve(true);
          }, error => {
            if (CONFIG.traceLoads) {
              console.log("exercisetypes load fail");
            }
            reject(error);
          }
        );
    });
  }

  @Cacheable()
  public getExercises2(): Observable<any> {
    return this.http.get(this.urlGetExercises);
  }
  
  // @Cacheable()
  public getExercise(exerciseId: number): Observable<any> {
    this.urlGetExercise = CONFIG.serverUrl + ENDPOINTS.exercise.get
    this.urlGetExercise = this.urlGetExercise.replace(":id", exerciseId.toString());
    return this.http.get(this.urlGetExercise);
  }
  @Cacheable()
  getExercisesDetail(): Observable<IExerciseDetail[]> {
    const url = CONFIG.serverUrl + ENDPOINTS.exercisesDetail.get;
    return this.http.get(url)
      .catch((error: HttpErrorResponse) => {
        return Observable.throw("Error retrieving exercises detail");
      })
      .map((response: any) => {
        if (CONFIG.traceLoads) {
          console.log("exercises detail load ok");
        }
        return response;
      });
  }

  @Cacheable()
  public getExerciseSkills(): Observable<any> {
    return this.http.get(this.urlGetSkills);
  }
  @Cacheable()
  public getExerciseMuscularGroups(): Observable<any> {
    return this.http.get(this.urlGetMuscularGroups);
  }
  @Cacheable()
  public getExerciseFundamentalMovements(): Observable<any> {
    return this.http.get(this.urlGetFundamentalMovements);
  }

  @Cacheable()
  public getExerciseDisciplines(): Observable<any> {
    return this.http.get(this.urlGetDisciplines);
  }

  async postFile(data: File): Promise<IPostFileResponse> {
    const url = CONFIG.serverUrl + ENDPOINTS.media.post;
    return new Promise<IPostFileResponse>((resolve, reject) => {
      const formData: FormData = new FormData();
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      const token: ISessionToken = JSON.parse(localStorage.getItem(ENUMS.keys.session));

      formData.append("files", data, data.name);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Authorization", "Bearer " + token.token);
      xhr.send(formData);
    });
  }
  
  @Cacheable()
  getGif(videoName: string): Observable<string> {
    const url = CONFIG.serverUrl + ENDPOINTS.gif.post.replace(":filename", videoName);
    return this.http.get(url)
      .catch((error: HttpErrorResponse) => {
        return Observable.throw("Error retrieving gif");
      })
      .map((response: any) => {
        return response.result;
      });
  }


  getExerciseThumbnailFromVideo(videoName: string) {
    const url = CONFIG.serverUrl + ENDPOINTS.thumbnail.get.replace(":filename", videoName);
    return this.http.get(url)
  }


  public putExercise(exercise: IExercise): Observable<any> {
    const data = exercise;
    return this.http.put(this.urlPutExercise, data);
  }

  public postExercise(exercise: IExercise): Observable<any> {
    const data = exercise;
    return this.http.post(this.urlPostExercise, data);
  }
  
  resetFilters(filterField) {
    for (const filterCheck of this.filterList.filter(x => x.field === filterField)) {
      filterCheck.checked = false;
    }
  }
  resetAllFilters() {
    for (const filterCheck of this.filterList) {
      filterCheck.checked = false;
    }
  }
  setCheckedByName(filterName: string) {
    for (const filterCheck of this.filterList) {
      if (filterCheck.name.toLowerCase() === filterName.toLowerCase()) {
        filterCheck.checked = true;
      }
    }
  }
}
