<div class="number-picker" fxLayout="column" fxLayoutAlign="stretch stretch" fxFlex>
    <h5 fxFlex="40" fxLayoutAlign="center center">
        {{title}}
    </h5>
    <div class="number-container" fxFlex="60" fxLayoutAlign="center center">
        <input #numberInput type="text" [value]="visibleData" [min]="min" [max]="max" (change)="dataChanged($event); numberInput.blur()">
        <div class="number-buttons" fxLayout="column" fxLayoutAlign="stretch stretch">
            <button fxFlex="50" (click)="changeNumber(1)" fxLayoutAlign="center center"><mat-icon>arrow_drop_up</mat-icon></button>
            <button fxFlex="50" (click)="changeNumber(-1)" fxLayoutAlign="center center"><mat-icon>arrow_drop_down</mat-icon></button>
        </div>
    </div>
</div>