<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" class="w-100">
  <div class="w-100">
    <mat-form-field class="w-100">
      <input type="text" matInput (keyup)="doSearch()" name="filtersearch" #filtersearch id="filtersearch" placeholder="Search id, name, tags" [(ngModel)]="this.exercisesService.filterSearch">
      <span uk-icon="search" class="search-icon"></span>
    </mat-form-field>
  </div>

  <ul uk-accordion="multiple: false" class="exercise-filter w-100">
    <!-- <li *ngIf="(this.filterStation.id !== 6 && this.filterStation.id !== 7) && this.advancedFilters"> -->
    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('userId')">
        <span uk-icon="trash" (click)="resetFilters('userId')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('userId')" href="#">Owner</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('userId'); let i = index;">
          <input #filter data-filterfield="userId" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>

    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('station')">
        <span uk-icon="trash" (click)="resetFilters('station')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('station')" href="#">Stations</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('station'); let i = index;">
          <input #filter data-filterfield="station" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>

    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('programs')">
        <span uk-icon="trash" (click)="resetFilters('programs')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('programs')" href="#">Programs</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('programs'); let i = index;">
          <input #filter data-filterfield="programs" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>
    <!--
      <li>
        <div class="remove-icon" *ngIf="hasFiltersOf('package')">
          <span uk-icon="trash" (click)="resetFilters('package')"></span>
        </div>
        <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('package')" href="#">Packages</a>
        <div class="uk-accordion-content">
          <label *ngFor="let filterItem of getFilterList('package'); let i = index;">
            <input #filter data-filterfield="package" type="checkbox" class="uk-checkbox" (change)="applyFilters()"
              [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
          </label>
        </div>
      </li>
      -->

    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('intensityLevel')">
        <span uk-icon="trash" (click)="resetFilters('intensityLevel')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('intensityLevel')" href="#">Intensity</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('intensityLevel'); let i = index;">
          <input #filter data-filterfield="intensityLevel" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>

    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('complexityLevel')">
        <span uk-icon="trash" (click)="resetFilters('complexityLevel')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('complexityLevel')" href="#">Complexity</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('complexityLevel'); let i = index;">
          <input #filter data-filterfield="complexityLevel" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>

    <!-- <li>
        <div class="remove-icon" *ngIf="hasFiltersOf('fundamentalMovement')">
          <span uk-icon="trash" (click)="resetFilters('fundamentalMovement')"></span>
        </div>
        <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('fundamentalMovement')" href="#">Fundamental
          Movements</a>
        <div class="uk-accordion-content">
          <label *ngFor="let filterItem of getFilterList('fundamentalMovement'); let i = index;">
            <input #filter type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked"
              [value]="true"> {{ filterItem.name }}
          </label>
        </div>
      </li> -->

    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('fundamentalMovement')">
        <span uk-icon="trash" (click)="resetFilters('fundamentalMovement')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('fundamentalMovement')" href="#">Fundamental
        Movements</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('fundamentalMovement'); let i = index;">
          <input #filter type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>

    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('muscularGroup')">
        <span uk-icon="trash" (click)="resetFilters('muscularGroup')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('muscularGroup')" href="#">Muscular groups</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('muscularGroup'); let i = index;">
          <input #filter data-filterfield="muscularGroup" data-filterfield2="muscularGroup2" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true">
          {{ filterItem.name }}
        </label>
      </div>
    </li>

    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('skills')">
        <span uk-icon="trash" (click)="resetFilters('skills')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('skills')" href="#">Skill</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('skills'); let i = index;">
          <input #filter data-filterfield="skills" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>

    <li *ngIf="this.advancedFilters">
      <div class="remove-icon" *ngIf="hasFiltersOf('discipline')">
        <span uk-icon="trash" (click)="resetFilters('discipline')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('discipline')" href="#">Discipline</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('discipline'); let i = index;">
          <input #filter data-filterfield="discipline" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>

    <li>
      <div class="remove-icon" *ngIf="hasFiltersOf('equipment')">
        <span uk-icon="trash" (click)="resetFilters('equipment')"></span>
      </div>
      <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('equipment')" href="#">Equipment</a>
      <div class="uk-accordion-content">
        <label *ngFor="let filterItem of getFilterList('equipment'); let i = index;">
          <input #filter data-filterfield="equipment" type="checkbox" class="uk-checkbox" (change)="applyFilters()" [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
        </label>
      </div>
    </li>


  </ul>
</div>