import { IWorkout } from '../../interfaces/models/i-workout';
import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { TimelineService } from "../../services/timeline.service";
import { NotificationService } from "../../services/notification.service";

import { ITimeline } from "../../interfaces/models/i-timeline";

import { MatTableDataSource } from "@angular/material/table";
import { CategoriesService } from '../../services/categories.service';
import { Category } from '../../interfaces/models/category';

@Component({
  selector: "my-timelines-list",
  templateUrl: "./my-timelines-list.component.html",
  styleUrls: ["./my-timelines-list.component.css"]
})
export class MyTimelinesListComponent implements OnInit {

  mediaUrl: string = CONFIG.sessionMediaURL;

  filterValue: string = "";

  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    creationDate: "Creation date",
    lastUpdate: "Last Update",
    actions: ""
  };

  categories: Category[];

  categoriesList: any[];
  
  timelinesToShow: IWorkout[];  
  savedTimelinesToShow: IWorkout[];  
  pendingTimelinesToShow: IWorkout[];  
  validatedTimelinesToShow: IWorkout[];  
  rejectedTimelinesToShow: IWorkout[];  
  disabledTimelinesToShow: IWorkout[];  

  displayedColumns = ['id', 'timelineName', 'timelineCategory', 'timelineOwner', 'timelineCreationDate', 'timelineLastUpdate'];
  dataSource = new MatTableDataSource;

  initialFilterIndex = 0;

  constructor(private route: ActivatedRoute,
    private categoriesService: CategoriesService,
    private router: Router) {

    this.timelinesToShow = this.route.snapshot.data.timelines;

    this.savedTimelinesToShow = this.timelinesToShow.filter(x => x.status == 0);
    this.validatedTimelinesToShow = this.timelinesToShow.filter(x => x.status == 1);
    this.pendingTimelinesToShow = this.timelinesToShow.filter(x => x.status == 2);
    this.rejectedTimelinesToShow = this.timelinesToShow.filter(x => x.status == 3);
    this.disabledTimelinesToShow = this.timelinesToShow.filter(x => x.status == 4);
  }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    
    this.fillCategoryFields();
    
  }

  fillCategoryFields(){
    this.categories = this.route.snapshot.data.categories;
    this.categoriesList = this.categoriesService.convertTreeToList(this.categories);

    this.timelinesToShow.forEach(timeline => {
      if(timeline.categories.length > 0){
        const found = this.categoriesList.find(c => c.id === timeline.categories[0]);
        if(found){
          let catName = found.fullpath;
          timeline.categories = catName;
        }
      }
    });
  }

  ngAfterViewInit() {
    //this.updateTableData(this.pendingTimelinesToShow);   

    // Procesamos filtro de tabs inicial
    this.route.queryParams
      .filter(params => params.filter)
      .subscribe(params => {
        switch (params.filter) {
          case 'saved': this.initialFilterIndex = 0; break;
          case 'pending': this.initialFilterIndex = 1; break;
          case 'validated': this.initialFilterIndex = 2; break;
          case 'rejected': this.initialFilterIndex = 3; break;
          case 'disabled': this.initialFilterIndex = 4; break;
          default: this.initialFilterIndex = 0; break;
        }
      }
      );
    this.tabIndexClicked(this.initialFilterIndex);

  }

  public updateTableData(data){
    this.dataSource = new MatTableDataSource(data);
  }

  public tabIndexClicked($event) {
    switch ($event) {
      case 0: this.updateTableData(this.savedTimelinesToShow); break;
      case 1: this.updateTableData(this.pendingTimelinesToShow); break;
      case 2: this.updateTableData(this.validatedTimelinesToShow); break;
      case 3: this.updateTableData(this.rejectedTimelinesToShow); break;
      case 4: this.updateTableData(this.disabledTimelinesToShow); break;
    }
    this.filterValue = "";
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.changeFilter(filterValue);
  }
  public changeFilter(filter: string){
    this.dataSource.filter = filter.trim().toLowerCase();
    this.filterValue = filter;
  }
  

}
