import { WorkoutFormComponent } from './components/block-creator/workout-form/workout-form.component';
import { BlockEditorComponent } from './components/block-creator/block-editor/block-editor.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./services/guards/auth-guard";

import { ROLES } from "../assets/roles";
import { CONFIG } from "../assets/config";
import { ENUMS } from "../assets/enums";

// import { ExerciseListComponent } from "./components/exercise-list/exercise-list.component";
// import { ExerciseDetailComponent } from "./components/exercise-detail/exercise-detail.component";
// import { ComboSequenceConfiguratorComponent } from "./components/combo-sequence-configurator/combo-sequence-configurator.component";
// import { SprintSequenceConfiguratorComponent } from "./components/sprint-sequence-configurator/sprint-sequence-configurator.component";
// import { SessionFormComponent } from "./components/session-form/session-form.component";
// import { SessionsListComponent } from "./components/sessions-list/sessions-list.component";
// import { TimelineFormComponent } from "./components/timeline-form/timeline-form.component";
// import { ProgramsListComponent } from "./components/programs-list/programs-list.component";
// import { TimelinesListComponent } from "./components/timelines-list/timelines-list.component";
import { PendingTimelinesListComponent } from "./components/pending-timelines-list/pending-timelines-list.component";
import { PendingTimelineFormComponent } from "./components/pending-timeline-form/pending-timeline-form.component";
// import { ExerciseSearchComponent } from "./components/exercise-search/exercise-search.component";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { ErrorComponent } from "./components/error/error.component";
// import { ProgramsBasicListComponent } from "./components/programs-basic-list/programs-basic-list.component";
// import { MusicManagerSongsComponent } from "./components/music-manager-songs/music-manager-songs.component";
// import { ProgramsPackagesListComponent } from "./components/programs-packages-list/programs-packages-list.component";
// import { FinishersListComponent } from "./components/finisher-list/finishers-list.component";
// import { FinisherFormComponent } from "./components/finisher-form/finisher-form.component";
// import { MusicManagerHomeComponent } from "./components/music-manager-home/music-manager-home.component";
// import { MusicManagerProvidersComponent } from "./components/music-manager-providers/music-manager-providers.component";
// import { MusicManagerSongsFormComponent } from "./components/music-manager-songs-form/music-manager-songs-form.component";
// import { MusicManagerPackagesComponent } from "./components/music-manager-packages/music-manager-packages.component";
// import { MusicManagerSchedulerComponent } from "./components/music-manager-scheduler/music-manager-scheduler.component";
// import { ProgramFormComponent } from "./components/program-form/program-form.component";

// import { ExerciseDetailResolver } from "./services/resolvers/exercise-detail-resolver";
// import { ComboSequenceConfiguratorResolver } from "./services/resolvers/combo-sequence-configurator-resolver";
// import { SprintSequenceConfiguratorResolver } from "./services/resolvers/sprint-sequence-configurator-resolver";
// import { SessionFormResolver } from "./services/resolvers/session-form-resolver";
import { MoodsAvailableResolver } from "./services/resolvers/moods-available-resolver";
import { PlaylistsAvailableResolver } from "./services/resolvers/playlists-available-resolver";
// import { WorkoutModesAvailableResolver } from "./services/resolvers/workoutmodes-available-resolver";
// import { ProgramsAvailableResolver } from "./services/resolvers/programs-available-resolver";
// import { OldProgramsAvailableResolver } from "./services/resolvers/old-programs-available-resolver";
import { ExercisesResolver } from "./services/resolvers/exercises-resolver";
import { StationsResolver } from "./services/resolvers/stations-resolver";
// import { SessionsResolver } from "./services/resolvers/sessions-resolver";
// import { TagsResolver } from "./services/resolvers/tags-resolver";
import { SkillsResolver } from "./services/resolvers/skills-resolver";
import { IntensityLevelsResolver } from "./services/resolvers/intensitylevels-resolver";
import { ComplexityLevelsResolver } from "./services/resolvers/complexitylevels-resolver";
import { FundamentalMovementsResolver } from "./services/resolvers/fundamentalmovements-resolver";
import { MuscularGroupsResolver } from "./services/resolvers/musculargroups-resolver";
import { DisciplinesResolver } from "./services/resolvers/disciplines-resolver";
import { EquipmentResolver } from "./services/resolvers/equipment-resolver";
import { TimelinesResolver } from "./services/resolvers/timelines-resolver";
import { TimelineFormResolver } from "./services/resolvers/timeline-form-resolver";
// import { TimelineCountdownsResolver } from "./services/resolvers/timeline-countdowns-resolver";
// import { TimelineWelcomesResolver } from './services/resolvers/timeline-welcomes-resolver';
// import { TimelineExplanationsResolver } from './services/resolvers/timeline-explanations-resolver';
// import { TimelineWarmUpsResolver } from './services/resolvers/timeline-warmups-resolver';
// import { TimelinePositioningsResolver } from './services/resolvers/timeline-positionings-resolver';
// import { TimelineResultsResolver } from './services/resolvers/timeline-results-resolver';
// import { TimelineCooldownsResolver } from './services/resolvers/timeline-cooldowns-resolver';
// import { TimelineEndingsResolver } from './services/resolvers/timeline-endings-resolver';
// import { TimelineFinishersResolver } from './services/resolvers/timeline-finishers-resolver';
// import { TimelineSessionsResolver } from "./services/resolvers/timeline-sessions-resolver";
import { PendingTimelinesResolver } from "./services/resolvers/pending-timelines-resolver";
// import { TriggersResolver, TriggerTypesResolver, TriggerAudiosResolver, TriggerMoodsResolver, HomeTriggerTypesResolver } from "./services/resolvers/triggers-resolver";
// import { MusicProgramResolver } from "./services/resolvers/music-program-resolver";
// import { PlaylistSongsResolver } from "./services/resolvers/playlist-songs-resolver";
// import { MusicProviderResolver } from "./services/resolvers/music-providers-resolver";
// import { SongsListResolver } from "./services/resolvers/songs-list-resolver";
// import { MusicPhasesResolver } from "./services/resolvers/music-phases-resolver";
// import { MusicProgramPackagesResolver } from "./services/resolvers/music-program-packages-resolver";
// import { PackagesManagementResolver } from "./services/resolvers/packages-management-resolver";
// import { MusicIntervalsResolver } from "./services/resolvers/music-intervals-resolver";
// import { MusicPackagesResolver } from "./services/resolvers/music-packages-resolver";
import { ProgramsByTypeResolver } from "./services/resolvers/programs-by-type-resolver";
// import { ProgramAmbiencePhasesResolver } from "./services/resolvers/programs-ambience-phases-resolver";
// import { ExecutionModesResolver } from "./services/resolvers/execution-modes-resolver";
// import { HomeFitnessSessionsResolver } from "./services/resolvers/home-fitness-sessions-resolver.service";
// import { HomeFitnessComponent } from "./components/home-fitness/home-fitness.component";
// import { HomeFitnessSessionListComponent } from "./components/home-fitness-session-list/home-fitness-session-list.component";
// import { HomeFitnessSessionFormComponent } from "./components/home-fitness-session-form/home-fitness-session-form.component";
// import { HomeFitnessWorkoutListComponent } from "./components/home-fitness-workout-list/home-fitness-workout-list.component";
// import { HomeFitnessWorkoutFormComponent } from "./components/home-fitness-workout-form/home-fitness-workout-form.component";
// import { HomeFitnessStudioWorkoutListComponent } from "./components/home-fitness-studio-workout-list/home-fitness-studio-workout-list.component";
// import { HomeFitnessWorkshopListComponent } from "./components/home-fitness-workshop-list/home-fitness-workshop-list.component";
// import { HomeFitnessChallengeListComponent } from "./components/home-fitness-challenge-list/home-fitness-challenge-list.component";
// import { HomeFitnessContentManagerComponent } from "./components/home-fitness-content-manager/home-fitness-content-manager.component";
// import { HomeFitnessWorkoutsResolver } from "./services/resolvers/home-fitness-workouts-resolver.service";
// import { HomeFitnessWorkoutResolver } from "./services/resolvers/home-fitness-workout-resolver.service";
import { LanguagesResolver } from "./services/resolvers/languages-resolver";
// import { BlockEditorResolver } from './services/resolvers/block-editor-resolver';
import { CategoriesManagerComponent } from './components/categories-manager/categories-manager.component';
import { CategoriesResolver } from './services/resolvers/categories-resolver';
// import { ExerciseFormComponent } from './components/exercise-form/exercise-form.component';
// import { WorkoutListComponent } from './components/workout-list/workout-list.component';
// import { WorkoutsResolver } from './services/resolvers/workouts-resolver';
// import { SharedContentResolver } from './services/resolvers/shared-content-resolver';
import { TimelineLogResolver } from './services/resolvers/timeline-log-resolver';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserListResolver } from './services/resolvers/user-list.resolver';
import { UserDetailResolver } from './services/resolvers/user-detail.resolver';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { ProfileListResolver } from './services/resolvers/profile-list.resolver';
import { GymListResolver } from './services/resolvers/gym-list.resolver';
import { ProfileListComponent } from './components/profile-list/profile-list.component';
import { RoleListResolver } from './services/resolvers/role-list.resolver';
import { PramacloudUserListResolver } from './services/resolvers/pramacloud-user-list.resolver';
import { WizardCreatorComponent } from './components/wizard-creator/wizard-creator.component';
import { WorkoutModesAvailableResolver } from './services/resolvers/workoutmodes-available-resolver';
import { TimelineFinishersResolver } from './services/resolvers/timeline-finishers-resolver';
import { ProgramAmbiencePhasesResolver } from './services/resolvers/programs-ambience-phases-resolver';
import { MyTimelinesListComponent } from './components/my-timelines-list/my-timelines-list.component';
import { MyTimelinesResolver } from './services/resolvers/my-timelines-resolver';
import { SessionsByCategoryComponent } from './components/sessions-by-category/sessions-by-category.component';
import { TimelineDetailComponent } from './components/timeline-detail/timeline-detail.component';


const routes: Routes = [
  // {
  //   path: "exercises",
  //   component: ExerciseListComponent,
  //   resolve: {
  //     exercises: ExercisesResolver,
  //     stationList: StationsResolver,
  //     programList: ProgramsByTypeResolver,
  //     intensityList: IntensityLevelsResolver,
  //     complexityList: ComplexityLevelsResolver,
  //     fundamentalMovementList: FundamentalMovementsResolver,
  //     muscularGroupList: MuscularGroupsResolver,
  //     skillList: SkillsResolver,
  //     disciplineList: DisciplinesResolver,
  //     equipmentList: EquipmentResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program, ENUMS.programTypes.package ] },

  // },
  // {
  //   path: "exercise/:id",
  //   component: ExerciseDetailComponent,
  //   resolve: {
  //     exercise: ExerciseDetailResolver,
  //     stations: StationsResolver,
  //     tags: TagsResolver,
  //     skills: SkillsResolver,
  //     intensityLevels: IntensityLevelsResolver,
  //     complexityLevels: ComplexityLevelsResolver,
  //     muscularGroups: MuscularGroupsResolver,
  //     fundamentalMovements: FundamentalMovementsResolver,
  //     disciplines: DisciplinesResolver,
  //     equipment: EquipmentResolver,
  //     programs: ProgramsByTypeResolver,
  //     oldPrograms: OldProgramsAvailableResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program, ENUMS.programTypes.package ] },

  // },
  // {
  //   path: "exercise-hf/:id",
  //   component: ExerciseFormComponent,
  //   resolve: {
  //     exercise: ExerciseDetailResolver,
  //     stations: StationsResolver,
  //     tags: TagsResolver,
  //     skills: SkillsResolver,
  //     intensityLevels: IntensityLevelsResolver,
  //     complexityLevels: ComplexityLevelsResolver,
  //     muscularGroups: MuscularGroupsResolver,
  //     fundamentalMovements: FundamentalMovementsResolver,
  //     disciplines: DisciplinesResolver,
  //     equipment: EquipmentResolver,
  //     programs: ProgramsByTypeResolver,
  //     oldPrograms: OldProgramsAvailableResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program, ENUMS.programTypes.package ] },

  // },
  // {
  //   path: "sequence/combo/:exerciseId",
  //   component: ComboSequenceConfiguratorComponent,
  //   resolve: { loaded: ComboSequenceConfiguratorResolver },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] }
  // },
  // {
  //   path: "sequence/sprint/:exerciseId",
  //   component: SprintSequenceConfiguratorComponent,
  //   resolve: { loaded: SprintSequenceConfiguratorResolver },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] }
  // },
  // {
  //   path: "session/:sessionId",
  //   component: SessionFormComponent,
  //   resolve: {
  //     loaded: SessionFormResolver,
  //     moodsAvailable: MoodsAvailableResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     session: SessionFormResolver,
  //     programs: ProgramsByTypeResolver,
  //     exercises: ExercisesResolver,
  //     stations: StationsResolver,
  //     executionModes: ExecutionModesResolver,

  //     stationList: StationsResolver,
  //     programList: ProgramsByTypeResolver,
  //     intensityList: IntensityLevelsResolver,
  //     complexityList: ComplexityLevelsResolver,
  //     fundamentalMovementList: FundamentalMovementsResolver,
  //     muscularGroupList: MuscularGroupsResolver,
  //     skillList: SkillsResolver,
  //     disciplineList: DisciplinesResolver,
  //     equipmentList: EquipmentResolver

  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program]  }
  // },
  // {
  //   path: "finisher/:sessionId",
  //   component: FinisherFormComponent,
  //   resolve: {
  //     loaded: SessionFormResolver,
  //     moodsAvailable: MoodsAvailableResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     session: SessionFormResolver,
  //     programs: ProgramsByTypeResolver,
  //     exercises: ExercisesResolver,
  //     stations: StationsResolver,

  //     stationList: StationsResolver,
  //     programList: ProgramsByTypeResolver,
  //     intensityList: IntensityLevelsResolver,
  //     complexityList: ComplexityLevelsResolver,
  //     fundamentalMovementList: FundamentalMovementsResolver,
  //     muscularGroupList: MuscularGroupsResolver,
  //     skillList: SkillsResolver,
  //     disciplineList: DisciplinesResolver,
  //     equipmentList: EquipmentResolver

  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] }
  // },
  // {
  //   path: "sessions",
  //   component: SessionsListComponent,
  //   resolve: {
  //     sessions: SessionsResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     programs: ProgramsByTypeResolver
  //   },
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "finishers",
  //   component: FinishersListComponent,
  //   resolve: {
  //     finishers: TimelineFinishersResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver
  //   },
  //   data: { role: [ROLES.creator]},
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "workouts",
  //   component: WorkoutListComponent,
  //   resolve: {
  //     workouts: WorkoutsResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver      
  //   },
  //   data: { role: [ROLES.creator]},
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "timeline/:timelineId",
  //   component: TimelineFormComponent,
  //   resolve: {
  //     timeline: TimelineFormResolver,
  //     loaded: TimelineFormResolver,
  //     programs: ProgramsByTypeResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     playlists: PlaylistsAvailableResolver,
  //     moods: MoodsAvailableResolver,
  //     countdowns: TimelineCountdownsResolver,
  //     welcomes: TimelineWelcomesResolver,
  //     explanations: TimelineExplanationsResolver,
  //     warmups: TimelineWarmUpsResolver,
  //     positionings: TimelinePositioningsResolver,
  //     results: TimelineResultsResolver,
  //     cooldowns: TimelineCooldownsResolver,
  //     endings: TimelineEndingsResolver,
  //     sessions: TimelineSessionsResolver,
  //     finishers: TimelineFinishersResolver,
  //     triggerList: TriggersResolver,
  //     triggerTypes: TriggerTypesResolver,
  //     triggerAudios: TriggerAudiosResolver,
  //     triggerMoods: TriggerMoodsResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },
  // },
  // {
  //   path: "timelines",
  //   component: TimelinesListComponent,
  //   resolve: {
  //     timelines: TimelinesResolver
  //   },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "programs",
  //   component: ProgramsListComponent,
  //   resolve: {
  //     programs: ProgramsByTypeResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },

  // },
  {
    path: "pending-timelines",
    component: PendingTimelinesListComponent,
    resolve: {
      timelines: PendingTimelinesResolver,
      categories: CategoriesResolver
    },
    canActivate: [AuthGuard]
  },
  {
    path: "pending-timeline/:timelineId",
    component: PendingTimelineFormComponent,
    resolve: {
      timeline: TimelineFormResolver,
      categories: CategoriesResolver,
      timelineLog: TimelineLogResolver
    },
    canActivate: [AuthGuard]
  },
  {
    path: "timeline-detail/:timelineId",
    component: TimelineDetailComponent,
    resolve: {
      timeline: TimelineFormResolver,
      categories: CategoriesResolver,
      timelineLog: TimelineLogResolver
    },
    canActivate: [AuthGuard]
  },
  {
    path: "my-timelines",
    component: MyTimelinesListComponent,
    resolve: {
      timelines: MyTimelinesResolver,
      categories: CategoriesResolver
    },
    canActivate: [AuthGuard]
  },  
  {
    path: "sessions-by-category",
    component: SessionsByCategoryComponent,
    resolve: {
      timelines: PendingTimelinesResolver,
      categories: CategoriesResolver
    },
    canActivate: [AuthGuard]
  },  
  // {
  //   path: "basics",
  //   component: ProgramsBasicListComponent,
  //   resolve: {
  //     programs: ProgramsByTypeResolver,
  //     stations: StationsResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },
  // },
  // {
  //   path: "music/providers",
  //   component: MusicManagerProvidersComponent,
  //   resolve: {
  //     providers: MusicProviderResolver
  //   },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "music/scheduler",
  //   component: MusicManagerSchedulerComponent,
  //   resolve: {
  //     programs: ProgramsByTypeResolver,
  //     intervals: MusicIntervalsResolver,
  //     packages: MusicPackagesResolver
  //   },
  //   data: { resolverProgramTypes: [ENUMS.programTypes.program]},
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "music/songs",
  //   component: MusicManagerSongsComponent,
  //   resolve: {
  //     songs: SongsListResolver,
  //     programs: ProgramsByTypeResolver,
  //     phases: MusicPhasesResolver,
  //     providers: MusicProviderResolver,
  //     packages: MusicPackagesResolver
  //   },
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "music/songs/upload",
  //   component: MusicManagerSongsFormComponent,
  //   resolve: {
  //     programs: ProgramsByTypeResolver,
  //     phases: MusicPhasesResolver,
  //     providers: MusicProviderResolver
  //   },
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "music/packages/:programId",
  //   component: MusicManagerPackagesComponent,
  //   resolve: {
  //     phases: MusicPhasesResolver,
  //     programs: ProgramsByTypeResolver,
  //     providers: MusicProviderResolver,
  //     packages: MusicProgramPackagesResolver,
  //     songs: SongsListResolver
  //   },
  //   canActivate: [AuthGuard],     
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },
  // },
  // {
  //   path: "music",
  //   component: MusicManagerHomeComponent,
  //   resolve: {
  //     programs: ProgramsByTypeResolver,
  //   },
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "packages",
  //   component: ProgramsPackagesListComponent,
  //   resolve: {
  //     programs: ProgramsByTypeResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     playlists: PlaylistsAvailableResolver,
  //     moods: MoodsAvailableResolver,
  //     countdowns: TimelineCountdownsResolver,
  //     welcomes: TimelineWelcomesResolver,
  //     explanations: TimelineExplanationsResolver,
  //     warmups: TimelineWarmUpsResolver,
  //     positionings: TimelinePositioningsResolver,
  //     results: TimelineResultsResolver,
  //     cooldowns: TimelineCooldownsResolver,
  //     endings: TimelineEndingsResolver,
  //     packages: MusicPackagesResolver,
  //     phases: MusicPhasesResolver,
  //     ambiencePhases: ProgramAmbiencePhasesResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program, ENUMS.programTypes.package] },
  // },
  // {
  //   path: "package/:packageId",
  //   component: ProgramFormComponent,
  //   resolve: {
  //     programs: ProgramsByTypeResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     playlists: PlaylistsAvailableResolver,
  //     moods: MoodsAvailableResolver,
  //     countdowns: TimelineCountdownsResolver,
  //     welcomes: TimelineWelcomesResolver,
  //     explanations: TimelineExplanationsResolver,
  //     warmups: TimelineWarmUpsResolver,
  //     positionings: TimelinePositioningsResolver,
  //     results: TimelineResultsResolver,
  //     cooldowns: TimelineCooldownsResolver,
  //     endings: TimelineEndingsResolver,
  //     packages: MusicPackagesResolver,
  //     phases: MusicPhasesResolver,
  //     ambiencePhases: ProgramAmbiencePhasesResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program, ENUMS.programTypes.package] },
  // },
  // {
  //   path: "homefitness",
  //   component: HomeFitnessComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] },
  // },
  // {
  //   path: "homefitness/sessions",
  //   component: HomeFitnessSessionListComponent,
  //   canActivate: [AuthGuard],
  //   resolve:{
  //     sessions: HomeFitnessSessionsResolver
  //   },
  //   data: { role: [ROLES.creator], type: ENUMS.homeFitnessSessionTypes.session },
  // },
  // {
  //   path: "homefitness/warmups",
  //   component: HomeFitnessSessionListComponent,
  //   canActivate: [AuthGuard],
  //   resolve:{
  //     sessions: HomeFitnessSessionsResolver
  //   },
  //   data: { role: [ROLES.creator], type: ENUMS.homeFitnessSessionTypes.warmup },
  // },
  // {
  //   path: "homefitness/cooldowns",
  //   component: HomeFitnessSessionListComponent,
  //   canActivate: [AuthGuard],
  //   resolve:{
  //     sessions: HomeFitnessSessionsResolver
  //   },
  //   data: { role: [ROLES.creator], type:ENUMS.homeFitnessSessionTypes.cooldown },
  // },
  // {
  //   path: "homefitness/session/:sessionId",
  //   component: HomeFitnessSessionFormComponent,
  //   resolve: {
  //     loaded: SessionFormResolver,
  //     moodsAvailable: MoodsAvailableResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     session: SessionFormResolver,
  //     programs: ProgramsByTypeResolver,
  //     exercises: ExercisesResolver,
  //     stations: StationsResolver,
  //     executionModes: ExecutionModesResolver,

  //     stationList: StationsResolver,
  //     programList: ProgramsByTypeResolver,
  //     intensityList: IntensityLevelsResolver,
  //     complexityList: ComplexityLevelsResolver,
  //     fundamentalMovementList: FundamentalMovementsResolver,
  //     muscularGroupList: MuscularGroupsResolver,
  //     skillList: SkillsResolver,
  //     disciplineList: DisciplinesResolver,
  //     equipmentList: EquipmentResolver

  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program], sessionType: ENUMS.homeFitnessSessionTypes.session  },
  // },
  // {
  //   path: "homefitness/warmup/:sessionId",
  //   component: HomeFitnessSessionFormComponent,
  //   resolve: {
  //     loaded: SessionFormResolver,
  //     moodsAvailable: MoodsAvailableResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     session: SessionFormResolver,
  //     programs: ProgramsByTypeResolver,
  //     exercises: ExercisesResolver,
  //     stations: StationsResolver,
  //     executionModes: ExecutionModesResolver,

  //     stationList: StationsResolver,
  //     programList: ProgramsByTypeResolver,
  //     intensityList: IntensityLevelsResolver,
  //     complexityList: ComplexityLevelsResolver,
  //     fundamentalMovementList: FundamentalMovementsResolver,
  //     muscularGroupList: MuscularGroupsResolver,
  //     skillList: SkillsResolver,
  //     disciplineList: DisciplinesResolver,
  //     equipmentList: EquipmentResolver

  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program], sessionType: ENUMS.homeFitnessSessionTypes.warmup},
  // },
  // {
  //   path: "homefitness/cooldown/:sessionId",
  //   component: HomeFitnessSessionFormComponent,
  //   resolve: {
  //     loaded: SessionFormResolver,
  //     moodsAvailable: MoodsAvailableResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     session: SessionFormResolver,
  //     programs: ProgramsByTypeResolver,
  //     exercises: ExercisesResolver,
  //     stations: StationsResolver,
  //     executionModes: ExecutionModesResolver,

  //     stationList: StationsResolver,
  //     programList: ProgramsByTypeResolver,
  //     intensityList: IntensityLevelsResolver,
  //     complexityList: ComplexityLevelsResolver,
  //     fundamentalMovementList: FundamentalMovementsResolver,
  //     muscularGroupList: MuscularGroupsResolver,
  //     skillList: SkillsResolver,
  //     disciplineList: DisciplinesResolver,
  //     equipmentList: EquipmentResolver

  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program], sessionType: ENUMS.homeFitnessSessionTypes.cooldown  },
  // },
  // {
  //   path: "homefitness/workouts",
  //   component: HomeFitnessWorkoutListComponent,
  //   canActivate: [AuthGuard],
  //   resolve:{
  //     workouts: HomeFitnessWorkoutsResolver
  //   },
  //   data: { role: [ROLES.creator] },
  // },
  // {
  //   path: "homefitness/workout/:workoutId",
  //   component: HomeFitnessWorkoutFormComponent,
  //   canActivate: [AuthGuard],
  //   resolve:{
  //     hfSessions: HomeFitnessSessionsResolver,
  //     workout: HomeFitnessWorkoutResolver,
  //     programs: ProgramsByTypeResolver,
  //     languages: LanguagesResolver,
  //     triggerList: TriggersResolver,
  //     triggerTypes: HomeTriggerTypesResolver,
  //     triggerAudios: TriggerAudiosResolver,
  //     triggerMoods: TriggerMoodsResolver
  //   },
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program] },
  // },
  // {

  //   path: "homefitness/studioworkouts",
  //   component: HomeFitnessStudioWorkoutListComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] },
  // },
  // {
  //   path: "homefitness/workshops",
  //   component: HomeFitnessWorkshopListComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] },
  // },
  // {
  //   path: "homefitness/challenges",
  //   component: HomeFitnessChallengeListComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] },
  // },
  // {
  //   path: "homefitness/content",
  //   component: HomeFitnessContentManagerComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] },
  // },
  // {
  //   path: "blockcreator/editor/:workoutId",
  //   component: WorkoutFormComponent,
  //   resolve: {
  //     workout: BlockEditorResolver,
  //     sharedContent: SharedContentResolver,
  //     //exercises: ExercisesResolver,

  //     //session: SessionFormResolver,
  //     programs: ProgramsByTypeResolver,
  //     categories: CategoriesResolver,
  //     //loaded: SessionFormResolver,

  //     // moodsAvailable: MoodsAvailableResolver,
  //     // workoutModesAvailable: WorkoutModesAvailableResolver,
  //     // stations: StationsResolver,
  //     // executionModes: ExecutionModesResolver,
  //     stationList: StationsResolver,
  //     programList: ProgramsByTypeResolver,
  //     intensityList: IntensityLevelsResolver,
  //     complexityList: ComplexityLevelsResolver,
  //     fundamentalMovementList: FundamentalMovementsResolver,
  //     muscularGroupList: MuscularGroupsResolver,
  //     skillList: SkillsResolver,
  //     disciplineList: DisciplinesResolver,
  //     equipmentList: EquipmentResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program, ENUMS.programTypes.package ] },
  // },

  // {
  //   path: 'users',
  //   component: UsersComponent,
  //   resolve: { userList: UserListResolver },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] }
  // },
  // {
  //   path: 'user/:id',
  //   component: UserDetailComponent,
  //   resolve: { user: UserDetailResolver, profileList: ProfileListResolver },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] }
  // },

  {
    path: "users",
    component: UserListComponent,
    resolve: {
      users: UserListResolver,
    },
    canActivate: [AuthGuard],
    data: { role: [ROLES.creator] },
  },
  {
    path: "user/:id",
    component: UserDetailComponent,
    resolve: {
      user: UserDetailResolver,
      gyms: GymListResolver,
      profiles: ProfileListResolver,
      pramacloudUsers: PramacloudUserListResolver
    },
    canActivate: [AuthGuard],
    data: { role: [ROLES.creator] },
  },
  // {
  //   path: "profiles",
  //   component: ProfileListComponent,
  //   resolve: {
  //     profiles: ProfileListResolver,
  //     rolesAvailable: RoleListResolver,
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator] },
  // },
  {
    path: "categories",
    component: CategoriesManagerComponent,
    resolve: {
      categories: CategoriesResolver,
    },
    canActivate: [AuthGuard],
    data: { role: [ROLES.creator] },
  },
  // {
  //   path: "wizard",
  //   component: WizardCreatorComponent,
  //   resolve: {
  //     categories: CategoriesResolver,

  //     exercises: ExercisesResolver,
  //     stationList: StationsResolver,
  //     programList: ProgramsByTypeResolver,
  //     intensityList: IntensityLevelsResolver,
  //     complexityList: ComplexityLevelsResolver,
  //     fundamentalMovementList: FundamentalMovementsResolver,
  //     muscularGroupList: MuscularGroupsResolver,
  //     skillList: SkillsResolver,
  //     disciplineList: DisciplinesResolver,
  //     equipmentList: EquipmentResolver,
  //     workoutModesAvailable: WorkoutModesAvailableResolver,
  //     finishersAvailable: TimelineFinishersResolver,
  //     ambiencesAvailable: ProgramAmbiencePhasesResolver
  //   },
  //   canActivate: [AuthGuard],
  //   data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program, ENUMS.programTypes.package ] },
  // },
  {
    path: "wizard",    
    redirectTo: "my-timelines",
    pathMatch: "full"
  },
  {
    path: "wizard/:timelineId",
    component: WizardCreatorComponent,
    resolve: {
      timeline: TimelineFormResolver,
      categories: CategoriesResolver,

      exercises: ExercisesResolver,
      stationList: StationsResolver,
      programList: ProgramsByTypeResolver,
      intensityList: IntensityLevelsResolver,
      complexityList: ComplexityLevelsResolver,
      fundamentalMovementList: FundamentalMovementsResolver,
      muscularGroupList: MuscularGroupsResolver,
      skillList: SkillsResolver,
      disciplineList: DisciplinesResolver,
      equipmentList: EquipmentResolver,
      workoutModesAvailable: WorkoutModesAvailableResolver,
      finishersAvailable: TimelineFinishersResolver,
      ambiencesAvailable: ProgramAmbiencePhasesResolver,
      moodsAvailable: MoodsAvailableResolver,
      playlistsAvailable: PlaylistsAvailableResolver,
    },
    canActivate: [AuthGuard],
    data: { role: [ROLES.creator], resolverProgramTypes: [ENUMS.programTypes.program, ENUMS.programTypes.package ] },
  },
  {
    path: "home",
    component: HomeComponent,
    redirectTo: ""
  },
  {
    path: "",
    component: HomeComponent,
    resolve: {
      // exercises: ExercisesResolver,
      // sessions: SessionsResolver,
      timelines: TimelinesResolver
    },
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "error",
    component: ErrorComponent
  },
  // {
  //   path: "exercises/search",
  //   component: ExerciseSearchComponent
  // },
  {
    path: "*",
    redirectTo: "",
    pathMatch: "full"
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
