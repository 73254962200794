import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'number-picker',
  templateUrl: './number-picker.component.html',
  styleUrls: ['./number-picker.component.scss']
})
export class NumberPickerComponent implements OnInit {

  @Input() number: any;
  @Input() title: string;
  @Input() min: number = 0;
  @Input() max: number = 999;
  @Input() increment: number = 1;
  @Input() isTime: boolean = false;
  @Input() hasDefault: boolean = false;

  @Output() numberChange = new EventEmitter<number>();

  visibleData: string;

  constructor() { }

  ngOnInit(): void {
    this.updateData();
  }

  ngOnChanges() {
    this.updateData();
  }


  changeNumber(change: number) {
    // let objective = this.number + change;
    // let numberChange = setInterval(()=>{
    //   let diff = change/Math.abs(change);
    //   this.number += diff;
    //   if(this.number == objective)
    //     clearInterval(numberChange);
    // }, 10);
    this.number = Number(this.number) + Number(change * this.increment);
    this.number = this.number < this.min ? this.min : this.number;
    this.number = this.number > this.max ? this.max : this.number;
    this.updateData();
    this.numberChange.emit(this.number);
  }

  updateData() {
    if (this.isTime)
      this.visibleData = this.secondsToMinutes(this.number);
    else
      this.visibleData = this.number;

    if (this.hasDefault && this.number <= 0) {
      this.visibleData = "Default"
    }
  }

  dataChanged($event) {
    const inputData = $event.target.value;
    const split = inputData.split(/[\s.:]+/);
    if (this.isTime && split.length == 2) {
      const mins = Number(split[0]);
      const secs = Number(split[1]);
      const timeInSeconds = mins * 60 + secs;
      this.number = timeInSeconds;
    } else if (this.isTime && split.length == 1) {
      this.number = split[0];
    } else {
      this.number = 0;
    }
    this.updateData();
  }

  secondsToMinutes(seconds: number): string {
    seconds = seconds >= 0 ? seconds : 0;
    const mins = Math.floor(seconds / 60);
    const rsecs = seconds % 60;
    return mins + ":" + (rsecs <= 9 ? "0" + rsecs : rsecs);
  }
}
