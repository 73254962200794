<div class="wizard-container" *ngIf="wizardService.wizardData">

    <!-- MODE SELECTION -->
    <div fxFlex="100" fxLayoutAlign="center center" *ngIf="wizardService.currentWizardStep?.key === 'trainingTypeSelect'">
        <div class="wizard-selector" fxLayout="column" fxLayoutGap="50px" fxFlex="50">
            <div class="wizard-mode-select" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" fxFlexAlign="stretch" *ngFor="let mode of wizardService.workoutModesAvailable" (click)="workoutModeSelect(mode)">
                <img [src]="'assets/img/mode_' + mode.id + '.jpg'">
                <div class="wizard-mode-text">{{mode.name}}</div>
            </div>

        </div>
    </div>


    <!-- MAIN LAYOUT -->
    <div fxLayout="column" fxFlex="100" *ngIf="wizardService.currentWizardStep?.id >= 20">

        <!-- WORKOUT EXERCISE LIST -->
        <exercise-grid [stations]="wizardService.wizardData.session.stations" fxFlex="80" *ngIf="wizardService.currentWizardStep.id >= 20 && wizardService.currentWizardStep.id !== 80"></exercise-grid>

        <!-- FINISHER EXERCISE -->
        <div class="wizard-exercise-block" fxFlex="100" fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="space-between stretch" *ngIf="wizardService.currentWizardStep.id == 80">
            <div fxLayout="row" fxFlex="100" fxLayoutGap="10px">

                <div class="wizard-grid-info p-3" fxFlex="20">
                    <div class="wizard-exercise-name" fxLayout="column" fxLayoutAlign="center center" class="pb-2">
                        <h1 class="color-black">Finisher</h1>
                        <h5 class="color-black" *ngIf="wizardService.wizardData.finisher?.finisher?.name">{{wizardService.wizardData.finisher?.finisher?.name}}</h5>
                        <h5 class="color-black" *ngIf="!wizardService.wizardData.finisher?.finisher">NO FINISHER</h5>
                    </div>
                    <div fxLayoutAlign="center center" class="pb-2">
                        <img src="assets/img/exerciseDeleteIcon.png" class="finisher-menu-icon m-2 cursor-pointer" (click)="clearFinisherExercise()" *ngIf="wizardService.wizardData.finisher?.finisher">
                        <img src="assets/img/exerciseIcon.png" class="finisher-menu-icon m-2 cursor-pointer" (click)="openSelectFinisherModal()" *ngIf="!wizardService.wizardData.finisher?.finisher">
                    </div>
                    <div fxLayoutAlign="center center" class="pb-2" *ngIf="wizardService.wizardData.finisher?.finisher?.rounds[0]">
                        <number-display title="Rounds" [number]="wizardService.wizardData.finisher?.finisher?.rounds.length" [isTime]="false" [bigText]="false"></number-display>
                    </div>
                    <div fxLayoutAlign="center center" class="pb-2" *ngIf="wizardService.wizardData.finisher?.finisher?.rounds[0].work">
                        <number-display title="Work" [number]="wizardService.wizardData.finisher?.finisher?.rounds[0].work" [isTime]="true" [bigText]="false"></number-display>
                    </div>
                    <div fxLayoutAlign="center center" class="pb-2" *ngIf="wizardService.wizardData.finisher?.finisher?.rounds[0].rest && wizardService.wizardData.finisher?.finisher?.exercises.length > 1">
                        <number-display title="Rest" [number]="wizardService.wizardData.finisher?.finisher?.rounds[0].rest" [isTime]="true" [bigText]="false"></number-display>
                    </div>
                    <div fxLayoutAlign="center center" *ngIf="wizardService.wizardData.finisher?.finisher?.rounds[0].extraRest">
                        <number-display title="Rest between rounds" [number]="wizardService.wizardData.finisher?.finisher?.rounds[0].extraRest && wizardService.wizardData.finisher?.finisher?.rounds.length > 1" [isTime]="true" [bigText]="false"></number-display>
                    </div>
                </div>


                <div class="wizard-exercise background-transparent" fxFlex="80" fxLayoutAlign="center center">
                    <ng-container *ngIf="wizardService.wizardData.finisher?.finisher?.id">
                        <div fxFlex fxLayout="row wrap" fxLayoutAlign="center center">
                            <div *ngFor="let exercise of wizardService.wizardData.finisher?.finisher.exercises">
                                <img [src]="CONFIG.exercisesMediaURL + exercise.exercise + '.gif'">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxFlex="20" fxLayoutGap="10px" class="p-1">
            <!-- <div class="wizard-grid-info" fxFlex *ngIf="wizardService.currentWizardStep.id >= 20 && wizardService.currentWizardStep.id !== 80">
                <div class="menu-title">Finisher</div>
            </div> -->

            <!-- MAIN TIMES CONFIG -->
            <!-- <div class="wizard-grid-info" fxFlex="90" *ngIf="wizardService.currentWizardStep.id >= 20 && wizardService.currentWizardStep.id !== 80"> -->
            <div fxFlex fxLayout="row" fxLayoutGap="10px" *ngIf="wizardService.wizardData?.session">
                <div class="wizard-grid-info px-3" fxLayout="column" fxFlex fxLayoutGap="10px">
                    <div fxLayout="row" fxFlex="100" fxLayoutGap="10px">
                        <number-picker [title]="wizardService.wizardData.session.workoutMode.name + 's'" [(number)]="wizardService.wizardData.session.rounds" (numberChange)="dataChanged()" min="1" max="15" increment="1"></number-picker>
                        <number-picker title="Work" [(number)]="wizardService.wizardData.session.workTime" (numberChange)="dataChanged()" min="5" increment="5" isTime="true"></number-picker>
                        <number-picker title="Rest" [(number)]="wizardService.wizardData.session.restTime" (numberChange)="dataChanged()" min="0" increment="5" isTime="true"></number-picker>
                        <number-picker [title]="'Time between ' + wizardService.wizardData.session.workoutMode.name + 's'" [(number)]="wizardService.wizardData.session.extraRestTime" (numberChange)="dataChanged()" min="0" increment="5" isTime="true"></number-picker>
                        <!-- <div class="vertical-divider"></div>
                        <number-picker [title]="'Circuit repeat'" [(number)]="wizardService.wizardData.circuitRepeatTimes" (numberChange)="dataChanged()" min="0" max="15" increment="1" *ngIf="wizardService.currentWizardStep.id >= 200"></number-picker>
                        <number-picker [title]="'Time between circuits'" [(number)]="wizardService.wizardData.timeBetweenCircuits" (numberChange)="dataChanged()" min="0" increment="5" isTime="true" *ngIf="wizardService.wizardData.circuitRepeatTimes > 1 && wizardService.currentWizardStep.id >= 200"></number-picker>
                        <div class="vertical-divider" *ngIf="wizardService.wizardData.circuitRepeatTimes > 1 && wizardService.currentWizardStep.id >= 200"></div>
                        <number-picker [title]="'Cardio stations'" [(number)]="wizardService.wizardData.cardioStations" (numberChange)="dataChanged(); wizardService.cardioChange()" min="0" max="15" increment="1" *ngIf="wizardService.currentWizardStep.id >= 200"></number-picker>
                        <number-picker [title]="'Time to cardio stations'" [(number)]="wizardService.wizardData.timeBetweenFunctionalAndCardioStations" (numberChange)="dataChanged()" min="0" increment="5" isTime="true" *ngIf="wizardService.wizardData.cardioStations>0 && wizardService.currentWizardStep.id >= 200"></number-picker> -->


                    </div>
                </div>
                <div fxLayout="row" fxFlex class="wizard-grid-info" *ngIf="wizardService.currentWizardStep.id >= 200">
                    <div fxLayout="column" fxFlex class="wizard-tabs-container">
                        <div class="wizard-tab" fxFlex fxLayoutAlign="center center" [ngClass]="{'wizard-tab-selected': selectedTab===1}" (click)="selectedTab=1">Circuit repeat</div>
                        <div class="wizard-tab" fxFlex fxLayoutAlign="center center" [ngClass]="{'wizard-tab-selected': selectedTab===2}" (click)="selectedTab=2">Cardio stations</div>
                        <div class="wizard-tab" fxFlex fxLayoutAlign="center center" [ngClass]="{'wizard-tab-selected': selectedTab===3}" (click)="selectedTab=3">Pre/Post session</div>
                    </div>
                    <div fxLayout="row">
                        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedTab===1">
                            <number-picker [title]="'Circuit repeat'" [(number)]="wizardService.wizardData.circuitRepeatTimes" (numberChange)="dataChanged()" min="0" max="15" increment="1" *ngIf="wizardService.currentWizardStep.id >= 200"></number-picker>
                            <number-picker [title]="'Time between circuits'" [(number)]="wizardService.wizardData.timeBetweenCircuits" (numberChange)="dataChanged()" min="0" increment="5" isTime="true" *ngIf="wizardService.wizardData.circuitRepeatTimes > 1 && wizardService.currentWizardStep.id >= 200"></number-picker>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedTab===2">
                            <number-picker [title]="'Cardio stations'" [(number)]="wizardService.wizardData.cardioStations" (numberChange)="dataChanged(); wizardService.cardioChange()" min="0" max="15" increment="1" *ngIf="wizardService.currentWizardStep.id >= 200"></number-picker>
                            <number-picker [title]="'Time to cardio stations'" [(number)]="wizardService.wizardData.timeBetweenFunctionalAndCardioStations" (numberChange)="dataChanged()" min="0" increment="5" isTime="true" *ngIf="wizardService.wizardData.cardioStations>0 && wizardService.currentWizardStep.id >= 200"></number-picker>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedTab===3">
                            <number-picker [title]="'WarmUp time'" [(number)]="wizardService.wizardData.warmupTime" (numberChange)="dataChanged()" min="0" increment="5" isTime="true" hasDefault="true"></number-picker>
                            <number-picker [title]="'CoolDown time'" [(number)]="wizardService.wizardData.cooldownTime" (numberChange)="dataChanged()" min="0" increment="5" isTime="true" hasDefault="true"></number-picker>
                        </div>
                    </div>
                </div>
                <!-- <div class="vertical-divider" *ngIf="wizardService.wizardData.cardioStations>0 && wizardService.currentWizardStep.id >= 200"></div> -->
                <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="stretch center" class="wizard-grid-info px-3">
                    <div fxLayout="row" fxFlex="100" fxLayoutGap="10px">
                        <number-display fxFlex="100" title="Total time" [number]="wizardService.calculateTotalTime()" min="0" increment="5" isTime="true" background="false" bigText="true"></number-display>
                    </div>
                </div>
                <div fxLayout="column wrap" fxLayoutGap="0px" fxLayoutAlign="end center" class="wizard-grid-info px-3 py-1" *ngIf="wizardService.lastWizardStep.id > 80">
                    <div fxFlex="33.3333" fxLayoutAlign="center center" *ngIf="wizardService.lastWizardStep.id > 80">
                        <button class="wizard-menu-button" (click)="goToStep('finisherConfig')">
                            <mat-icon *ngIf="wizardService.wizardData.finisher.finisher">flag</mat-icon>
                            <mat-icon *ngIf="!wizardService.wizardData.finisher.finisher">outlined_flag</mat-icon>
                        </button>
                    </div>
                    <div fxFlex="33.3333" fxLayoutAlign="center center" *ngIf="wizardService.lastWizardStep.id >= 90">
                        <button class="wizard-menu-button" (click)="goToStep('ambienceToggle')">
                            <mat-icon>lightbulb</mat-icon>
                        </button>
                    </div>
                    <div fxFlex="33.3333" fxLayoutAlign="center center" *ngIf="wizardService.lastWizardStep.id >= 130">
                        <button class="wizard-menu-button" (click)="goToStep('heartrateToggle')">
                            <mat-icon *ngIf="wizardService.wizardData.heartRate">favorite</mat-icon>
                            <mat-icon *ngIf="!wizardService.wizardData.heartRate">favorite_border</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div fxFlex="10" fxLayoutAlign="stretch stretch">
                <button class="wizard-button-next w-100" (click)="goToNextStep()" *ngIf="wizardService.currentWizardStep.id < 200 && wizardService.currentWizardStep.id == wizardService.lastWizardStep.id && wizardService.canContinue()">NEXT</button>
                <button class="wizard-button-next w-100" (click)="goToLastStep()" *ngIf="wizardService.currentWizardStep.id != wizardService.lastWizardStep.id && wizardService.canContinue()">BACK</button>
                <button class="wizard-button-next w-100" (click)="sendWizard()" *ngIf="wizardService.currentWizardStep.id == 200 && wizardService.currentWizardStep.id == wizardService.lastWizardStep.id && wizardService.canContinue()">SAVE</button>
                <button class="wizard-button-next button-disabled w-100" *ngIf="!wizardService.canContinue()">NEXT</button>
            </div>
        </div>
    </div>

    <!-- MODAL EXERCISE LIST -->
    <exercise-select-modal></exercise-select-modal>
    <!-- MODAL CARDIO -->
    <cardio-modal (doStepProcedures)="doStepProcedures()"></cardio-modal>
    <!-- MODAL CIRCUIT REPEAT -->
    <circuit-repeat-modal (doStepProcedures)="doStepProcedures()"></circuit-repeat-modal>
    <!-- MODAL WARMUP COOLDOWN -->
    <warmup-cooldown-modal (doStepProcedures)="doStepProcedures()"></warmup-cooldown-modal>
    <!-- MODAL FINISHER -->
    <finisher-modal (doStepProcedures)="doStepProcedures()"></finisher-modal>
    <!-- MODAL FINISHER LIST -->
    <finisher-select-modal></finisher-select-modal>
    <!-- MODAL HEART RATE -->
    <heartrate-modal (doStepProcedures)="doStepProcedures()"></heartrate-modal>
    <!-- AMBIENCE SELECT -->
    <ambience-select-modal (doStepProcedures)="doStepProcedures()"></ambience-select-modal>
    <!-- MODAL NAME -->
    <name-modal (doStepProcedures)="doStepProcedures()"></name-modal>
    <!-- MODAL SESSION COPY -->
    <session-copy-modal></session-copy-modal>

</div>