import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WizardService } from '../../../services/wizard.service';
import { IStation } from '../../../interfaces/models/i-station';

@Component({
  selector: 'finisher-modal',
  templateUrl: './finisher-modal.component.html',
  styleUrls: ['./finisher-modal.component.scss']
})
export class FinisherModalComponent implements OnInit {

  @Output() doStepProcedures = new EventEmitter();

  @ViewChild("finisherModal") finisherModal;
  modalRef: BsModalRef;

  finisherConfig: boolean = false;

  constructor(private modalService: BsModalService,
    private wizardService: WizardService) { }

  ngOnInit(): void {
  }

  public open() {
    this.modalRef = this.modalService.show(this.finisherModal, { class: 'modal-dialog-centered modal-md wizard-modal', ignoreBackdropClick: true });
  }
  public close() {
    this.modalRef.hide();
  }

  public modalAction(action: number) {
    switch (action) {
      case 0:
        this.wizardService.wizardData.finisher.finisher = null;        
        if(!this.wizardService.isBackMode()){
          this.wizardService.goToStep('ambienceToggle');
        }else{
          this.wizardService.goToLastStep();
        }
        this.close();
        break;
      case 1:
        // this.finisherConfig = true;
        this.wizardService.goToNextStep();
        this.close();
        break;
    }
    this.doStepProcedures.emit();
  }



}
