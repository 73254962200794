<ng-template #ambienceSelectModal>
    <div fxLayout="column" fxFlex="100" *ngIf="ambienceSelectStage===0">
        <ambience-list [currentAmbience]="this.wizardService.wizardData.ambience" (ambienceSelected)="ambienceSelected($event)"></ambience-list>
    </div>

    <!-- Pregunta Personalize Si/Skip -->
    <div class="modal-content" *ngIf="ambienceSelectStage===1">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">Personalize ambience</h4>
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="start start">
                <div class="modal-selection" fxLayout="row" fxLayoutAlign="start center" (click)="nextAmbienceSelectStage()">
                    <div class="modal-selection-icon" fxLayoutAlign="center center">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div fxFlex>
                        Personalize
                    </div>
                </div>
                <div class="modal-selection" fxLayout="row" fxLayoutAlign="start center" (click)="exitAmbienceSelect()">
                    <div class="modal-selection-icon" fxLayoutAlign="center center">
                        <mat-icon>close</mat-icon>
                    </div>
                    <div fxFlex>
                        Skip
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- Seleccion de Mood -->
    <div class="modal-content" *ngIf="ambienceSelectStage===2">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">Mood</h4>
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="start start">

                <!-- <mat-select [(ngModel)]="selectedValue" name="mood">
                        <mat-option *ngFor="let mood of wizardService.moodsAvailable" [value]="mood.id">
                            <img />{{mood.name}}
                        </mat-option>
                    </mat-select> -->
                <h5>Work mood</h5>
                <div class="btn-group" dropdown fxFlex="100">
                    <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle">
                        <img class="bootiq-mood-icon" src="assets/moods/{{this.wizardService.wizardData.ambience.customMoodWork.id||0}}.png" />{{this.wizardService.wizardData.ambience.customMoodWork.moodName||'Select'}}
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" *ngFor="let mood of wizardService.moodsAvailable">
                            <div class="dropdown-item" (click)="this.wizardService.wizardData.ambience.customMoodWork = mood; changeObject(this.wizardService.wizardData.ambience.customMoodWork, mood)">
                                <img class="bootiq-mood-icon" [src]="'assets/moods/'+mood.id+'.png'" />{{mood.moodName}}
                            </div>
                        </li>
                    </ul>
                </div>

                <h5>Rest mood</h5>
                <div class="btn-group" dropdown fxFlex="100">
                    <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle">
                        <img class="bootiq-mood-icon" src="assets/moods/{{this.wizardService.wizardData.ambience.customMoodRest.id||0}}.png" />{{this.wizardService.wizardData.ambience.customMoodRest.moodName||'Select'}}
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" *ngFor="let mood of wizardService.moodsAvailable">
                            <div class="dropdown-item" (click)="this.wizardService.wizardData.ambience.customMoodRest = mood; changeObject(this.wizardService.wizardData.ambience.customMoodRest, mood)">
                                <img class="bootiq-mood-icon" [src]="'assets/moods/'+mood.id+'.png'" />{{mood.moodName}}
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="w-100" *ngIf="wizardService.wizardData.ambience.customMoodWork && wizardService.wizardData.ambience.customMoodRest">
                <div class="button-round" fxLayoutAlign="center center" (click)="nextAmbienceSelectStage()">
                    <mat-icon>check</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <!-- Seleccion de Playlist -->
    <div class="modal-content" *ngIf="ambienceSelectStage===3">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">Playlist</h4>
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="start start">

                <h5>Work playlist</h5>
                <div class="btn-group" dropdown fxFlex="100">
                    <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle">
                        {{this.wizardService.wizardData.ambience.customPlaylistWork.name||'Select'}}
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" *ngFor="let playlist of wizardService.playlistsAvailable">
                            <div class="dropdown-item" (click)="this.wizardService.wizardData.ambience.customPlaylistWork = playlist; changeObject(this.wizardService.wizardData.ambience.customPlaylistWork, playlist)">
                                {{playlist.name}}
                            </div>
                        </li>
                    </ul>
                </div>

                <h5>Rest playlist</h5>
                <div class="btn-group" dropdown fxFlex="100">
                    <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle">
                        {{this.wizardService.wizardData.ambience.customPlaylistRest.name||'Select'}}
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" *ngFor="let playlist of wizardService.playlistsAvailable">
                            <div class="dropdown-item" (click)="this.wizardService.wizardData.ambience.customPlaylistRest = playlist; changeObject(this.wizardService.wizardData.ambience.customPlaylistRest, playlist)">
                                {{playlist.name}}
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="w-100" *ngIf="wizardService.wizardData.ambience.customMoodWork && wizardService.wizardData.ambience.customMoodRest">
                <div class="button-round" fxLayoutAlign="center center" (click)="nextAmbienceSelectStage()">
                    <mat-icon>check</mat-icon>
                </div>
            </div>
        </div>
    </div>

</ng-template>