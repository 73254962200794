import { Component, OnInit ,AfterViewInit, Output, EventEmitter } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { ProgramService } from "../../services/program.service";
import { WorkoutModeService } from "../../services/workoutmode.service";
import { SessionService } from "../../services/session.service";
import { NotificationService } from "../../services/notification.service";

import { ISessionFilter } from "../../interfaces/models/i-session-filter";
import { ISession } from "../../interfaces/models/i-session";
import { IProgram } from "../../interfaces/models/i-program";
import { IWorkoutMode } from "../../interfaces/models/i-workout-mode";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: "finishers-list",
  templateUrl: "./finishers-list.component.html",
  styleUrls: ["./finishers-list.component.css"]
})
export class FinishersListComponent implements OnInit {

  @Output() finisherSelected = new EventEmitter();

  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    desc: "Description",
    workMode: "Description",
    actions: ""
  };

  filterId: string = "";
  filterName: string = "";
  filterWorkoutModes: number[] = [];
  filterPrograms: number[] = [];

  workoutModes: IWorkoutMode[];
  programs: IProgram[];
  sessions: ISession[];
  sessionsToShow: ISession[];
  currentFilter: ISessionFilter;

  mediaUrl: string = CONFIG.sessionMediaURL;

  displayedColumns = ['id', 'sessionName', 'sessionDescription'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private programsService: ProgramService,
    private sessionService: SessionService,
    private workoutModesService: WorkoutModeService,
    private notificationService: NotificationService) {

    this.sessionsToShow = this.route.snapshot.data.finishersAvailable;

    this.currentFilter = {
      filteredId: "",
      filteredName: "",
      filteredWorkoutModes: [],
      filteredPrograms: []
    };

  }

  ngOnInit() {

    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     window.scrollTo(0, 0);
    //   }
    // });
  }

  ngAfterViewInit() {    
    this.updateTableData();    
  }

  updateTableData(){
    this.dataSource = new MatTableDataSource(this.sessionsToShow);
  }

  selectFinisher(finisher: any){
    this.finisherSelected.emit(finisher);
  }

}
