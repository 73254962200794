import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

@Injectable()
export class GymsService {
  private urlGetGyms =  CONFIG.serverUrl + ENDPOINTS.bootiq.gyms.get; 

  constructor(public http: HttpClient) { }

  public getGyms(): Observable<any> {
    return this.http.get(this.urlGetGyms);
  }  

}
