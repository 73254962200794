<div class="home">

  <div fxLayout="column" fxLayoutAlign="center center" class="logo-background-container">
    <img src="./../../assets/img/logo-bootiq.png" class="logo-background" />
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill *ngIf="accessExercises || pavigymHome">
    <h5 fxFlex="nogrow">MAIN ACTIONS</h5>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="stretch" fxFill>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch" *ngIf="authService.isInRole(ENUMS.roles.creator_bootiq_wizard)">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">CREATOR</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Use the Wizard to create a new routine: you will be able to easily assign exercises, finishers, cardio stations, and define all the parameters.</p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/wizard']">
            GO TO CREATOR <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch" *ngIf="authService.isInRole(ENUMS.roles.creator_category_list)">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">CATEGORIES</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Change the configuration of your categories: create new ones, delete them, assign them to a parent category ...</p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/categories']">
            GO TO CATEGORIES <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch" *ngIf="authService.isInRole(ENUMS.roles.creator_bootiq_validation_list)">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">SESSION VALIDATION</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Validate or reject sessions from here, change session info such as name, description or assigned category</p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/pending-timelines']">
            GO TO VALIDATION <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch" *ngIf="authService.isInRole(ENUMS.roles.creator_bootiq_user_list)">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">USER MANAGER</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Manage your users: create new ones or edit existing users. Change info like name, geo data or profile and gym access</p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/users']">
            GO TO USER MANAGER <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

  </div>

</div>


<!-- USERS ICON -->
<!-- <a href="http://devmanager.pramacloud.com" target="_blank">
  <div class="home-section uk-width-1-5@s">
    <div class="home-section-header">
      <span class="home-label"> Manager <i class="fa fa-external-link"></i></span>
    </div>

    <div class="home-section-icon">
      <i class="fa fa-cogs home-icon"></i>
    </div>
  </div>
</a> -->