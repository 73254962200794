<ng-template #sessionCopyModal>
    <div class="modal-content">
        <div class="modal-header" fxLayoutAlign="center center">
            <h4 class="modal-title">Timeline copy</h4>
            <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button> -->
        </div>
        <div class="modal-body" fxLayout="column" fxLayoutAlign="center center" *ngIf="!wizardService.wizardSentStatus || wizardService.wizardSentStatus == -1">
            <div class="w-100 mb-1">
                <label>This timeline cannot be edited. If you continue, you will be editing a copy of this timeline and can save it with whatever name you want as a new timeline.</label>
            </div>

            <div class="w-100 mt-2" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">                
                <div fxLayout="column" fxLayoutAlign="end center" (click)="closeModal()" fxLayoutGap="10px">
                    <div class="button-round" fxLayoutAlign="center center">
                        <mat-icon>check</mat-icon>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</ng-template>