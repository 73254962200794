import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IUser } from '../interfaces/i-user';
import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

@Injectable()
export class UsersService {
  private urlGetUsers =  CONFIG.serverUrl + ENDPOINTS.bootiq.users.getUsers;
  // private urlGetUsers =  "http://devapi.pramacloud.com/bootiq/user";
  private urlGetUser =  CONFIG.serverUrl + ENDPOINTS.bootiq.users.getUser;
  private urlPutUser =  CONFIG.serverUrl + ENDPOINTS.bootiq.users.putUser;
  private urlPostUser = CONFIG.serverUrl + ENDPOINTS.bootiq.users.postUser;
  private urlDeleteUser = CONFIG.serverUrl + ENDPOINTS.bootiq.users.deleteUser;
  private urlGetPramacloudUsers = CONFIG.serverUrl + ENDPOINTS.bootiq.users.getPramacloudUsers;

  constructor(public http: HttpClient) { }

  public getUsers(): Observable<any> {
    return this.http.get(this.urlGetUsers);
  }

  public getUser(id): Observable<any> {
    return this.http.get(this.urlGetUser.replace(':id', id));
  }

  public putUser(user: IUser): Observable<any> {
    const data = user;
    return this.http.put(this.urlPutUser, data);
  }

  public postUser(user: IUser): Observable<any> {
    const data = user;
    return this.http.post(this.urlPostUser, data);
  }

  public deleteUser(id: number): Observable<any> {
    return this.http.delete(this.urlDeleteUser.replace(':id', id.toString()));
  }

  public getPramacloudUsers(): Observable<any> {
    return this.http.get(this.urlGetPramacloudUsers);
  }

}
