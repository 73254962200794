import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { IEquipment } from "../../interfaces/models/i-equipment";
import { IProgram } from "../../interfaces/models/i-program";
import { IStation } from "../../interfaces/models/i-station";
import { IIntensityLevel } from "../../interfaces/models/i-intensity-level";
import { ITag } from "../../interfaces/models/i-tag";
import { IUser } from "../../interfaces/i-user";

import { UsersService } from "../../services/users.service";
import { StationService } from "../../services/station.service";
import { NotificationService } from "../../services/notification.service";

import { ENUMS } from "../../../assets/enums";
import { CONFIG } from "../../../assets/config";

import { LoadingService } from "../../services/loading.service";
import { GlobalService } from "../../services/global.service";

@Component({
  selector: "user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.css"]
})
export class UserDetailComponent implements OnInit {

  @ViewChild('siteForm') siteForm: any;

  user: IUser = {
    id: 0,
    status: 1,
    email: "",
    name: "",
    lastName: "",
    password: "",
    birthDate: "",
    gender: "",
    city: "",
    zip: "",
    country: "",
    phone: "",
    creationDate: "",
    experience: 0,
    gymsAccess: [],
    profiles: [],
    allowNewsletter: true,
    allowNotifications: true,
    recoveryHash: "",
    refreshToken: "",
    refreshTokenExpires: "",
    idPramaCloud: 0
  };

  isNew: Boolean = false;

  editMode: boolean;

  formSubmitted = false;

  public profileList;
  public pramacloudUserList;

  constructor(private router: Router, private route: ActivatedRoute,
    private usersService: UsersService,
    private stationsService: StationService,
    public globalService: GlobalService,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.loadData();
  }


  loadData() {
    this.editMode = true; // Forced to true, originally false

    if (this.route.snapshot.data.user === 'new') {
      this.isNew = true;
      this.editMode = true;
    } else {
      this.user = this.route.snapshot.data.user;
    }

    this.profileList = this.route.snapshot.data.profiles;
    this.pramacloudUserList = this.route.snapshot.data.pramacloudUsers;
    this.pramacloudUserList.sort((a, b) => (a.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() > b.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) ? 1 : -1);

  }

  onSubmit() {
    this.formSubmitted = true;

    if (!this.siteForm.form.valid) {
      window.scroll(0, 0);
    } else {
      if (this.isNew) {
        this.createUser();
      } else {
        this.saveUser();
      }
    }
  }

  public saveUser() {
    this.usersService.putUser(this.user).subscribe(
      result => {
        //if (result) {
        this.notificationService.notify("User saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        //}
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createUser() {
    this.usersService.postUser(this.user).subscribe(
      result => {
        //if (result) {
        this.notificationService.notify("User created successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.router.navigate(["/users"]);
        //}
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  isValid(object: any) {
    if (object !== null && object !== undefined && object.id !== undefined) {
      if (object.id === 0 || object.id === null) {
        return false;
      }
    } else {
      if (object === 0 || object === "" || object === null) {
        return false;
      }
    }
    return true;
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  
}
