import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WizardService } from '../../../services/wizard.service';
import { IStation } from '../../../interfaces/models/i-station';

@Component({
  selector: 'ambience-select-modal',
  templateUrl: './ambience-select-modal.component.html',
  styleUrls: ['./ambience-select-modal.component.scss']
})
export class AmbienceSelectModalComponent implements OnInit {

  @ViewChild("ambienceSelectModal") ambienceSelectModal;

  @Output() doStepProcedures = new EventEmitter();

  modalRef: BsModalRef;

  ambienceSelectStage: number;
  
  constructor(private modalService: BsModalService,
    private wizardService: WizardService) { }

  ngOnInit(): void {
  }

  open(){
    this.ambienceSelectStage = 0;
    this.openModal();
  }

  public openModal() {
    this.modalRef = this.modalService.show(this.ambienceSelectModal, { class: 'modal-dialog-centered modal-md wizard-modal', ignoreBackdropClick: true });
  }
  public closeModal() {
    this.modalRef.hide();
  }

  public ambienceSelected($event){
    this.wizardService.wizardData.ambience.id = $event.id;
    this.nextAmbienceSelectStage();
  }

  public nextAmbienceSelectStage(){
    this.ambienceSelectStage++;
    if(this.ambienceSelectStage === 4){
      this.exitAmbienceSelect();
    }
  }
  
  public exitAmbienceSelect(){
    if(!this.wizardService.isBackMode()){
      this.wizardService.goToNextStep();
    }else{
      this.wizardService.goToLastStep();
    }
    this.doStepProcedures.emit();
    this.closeModal();
  }

  public changeObject(object){
    object.changed = true;
  }


}
