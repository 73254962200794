import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { IStation } from '../../../interfaces/models/i-station';

@Component({
  selector: 'exercise-select-modal',
  templateUrl: './exercise-select-modal.component.html',
  styleUrls: ['./exercise-select-modal.component.scss']
})
export class ExerciseSelectModalComponent implements OnInit {

  @ViewChild("exerciseSelectModal") exerciseSelectModal;

  modalRef: BsModalRef;
  currentStation: IStation;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  open(station: IStation){
    this.currentStation = station;
    this.openModal();
  }

  public openModal() {
    this.modalRef = this.modalService.show(this.exerciseSelectModal, Object.assign({}, { class: 'modal-dialog-centered modal-dialog modal-xl' }));
  }
  public closeModal() {
    this.modalRef.hide();
  }

  public exerciseSelected($event){
    this.currentStation.selectedExercise = $event;
    this.closeModal();
  }

}
