<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill class="category-manager">
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4 fxFlex="50">{{ categoryList?.length }} categories</h4>
      <div>
        <mat-form-field>
          <input #filter matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value=''; applyFilter('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

      </div>
    </div>

    <mat-table #categoryTable [dataSource]="dataSource" matSort class="session-table">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="50%"> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="90%" fxFlexAlign="start">
          <div>
            {{element.fullpath}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sessions">
        <mat-header-cell *matHeaderCellDef fxFlex="50%"> Timelines </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="50%">
          <div fxLayour="column" class="py-2 w-100">
            <div *ngIf="getTimelinesByCategory(element).length===0">No timelines in this category</div>
            <div *ngIf="getTimelinesByCategory(element).length===1">1 timeline in this category: </div>
            <div *ngIf="getTimelinesByCategory(element).length>1">{{getTimelinesByCategory(element).length}} timelines in this category: </div>            
            <div class="category-timelines">
              <div *ngFor="let timeline of getTimelinesByCategory(element)">
                <a [routerLink]="['/timeline-detail', timeline.id ]" class="standard-link">
                  {{timeline.name}}
                </a>
              </div>              
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>