<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <!-- <div fxLayout="column" fxFlex="20" fxFlexAlign="stretch">
  
    </div> -->
  
    <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">  
      <div fxLayout="row" fxLayoutAlign="space-between center">        
          <h4 fxFlex="50">{{ timelinesToShow?.length }} timelines</h4>
          <button [routerLink]="['/timeline', 'new']" class="uk-button prama-button medium-button">
              <i class="fa fa-plus-circle faSize"></i>
          </button>
      </div> 
  
      <mat-table #table [dataSource]="dataSource" matSort class="timeline-table">
  
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="5%">
            {{element.id}}
            <a [routerLink]="['/timeline', element.id ]" class="mat-row-link"></a>
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="timelineName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40%"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="40%">
            <h4>{{element.name}}</h4>
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="timelineCreationDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%"> Creation date </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="15%">
            {{element.creationDate | date: 'dd / MM / yyyy'}}<br>
            {{element.creationDate | date: 'H:mm'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timelineLastUpdate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%"> Last update </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="15%">
              {{element.lastUpdate | date: 'dd / MM / yyyy'}}<br>
              {{element.lastUpdate | date: 'H:mm'}}
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="timelineImage">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxLayoutAlign="end center">
            Image
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxLayoutAlign="end end">
            <img *ngIf="element" [src]="this.mediaUrl + element.image" class="list-image"/>
          </mat-cell>
        </ng-container>
  
  
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/timeline', row.id ]"></mat-row>
      </mat-table>
      <!-- <mat-paginator [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons></mat-paginator> -->
    </div>
  </div>
  

