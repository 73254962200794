import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WizardService } from '../../../services/wizard.service';

@Component({
  selector: 'session-copy-modal',
  templateUrl: './session-copy-modal.component.html',
  styleUrls: ['./session-copy-modal.component.scss']
})
export class SessionCopyModalComponent implements OnInit {

  @ViewChild("sessionCopyModal") sessionCopyModal;

  modalRef: BsModalRef;
  
  constructor(private modalService: BsModalService,
    public wizardService: WizardService) { }

  ngOnInit(): void {
  }

  open(){
    this.openModal();
  }

  public openModal() {
    this.modalRef = this.modalService.show(this.sessionCopyModal, Object.assign({}, { class: 'modal-dialog-centered modal-md wizard-modal' }));
  }
  public closeModal() {
    this.modalRef.hide();
  }

}
