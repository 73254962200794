import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from "../../services/auth.service";
import { ENUMS } from "../../../assets/enums";
import { IUser, IUserMini } from "../../interfaces/i-user";

@Component({
  selector: "user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"]
})
export class UserListComponent implements OnInit, AfterViewInit {

  headers = {
    email: "Email",
    name: "Name",
    status: "Status",
    creationDate: "Created on",
    actions: "Actions"
  };

  users: IUserMini[];

  userList: IUserMini[] = [];
  userListRange: number = 0;
  userListAddOnEachScroll: number = 10;

  previewImage = "";

  pavigym: boolean;
  userId: number;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // User list
  // displayedColumns = ['id', 'name', 'station', 'program', 'muscularGroup', 'intensity', 'equipment', 'thumbnail'];
  displayedColumns = ['email', 'name', 'profiles'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
    this.userList = this.route.snapshot.data.users;
    this.pavigym = this.authService.isInRole(ENUMS.roles.pavigym);
    this.userId = this.authService.getUserId();

    //this.usersToShow = this.usersToShow.slice(0, 40); // Limit to 40 for debugging
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterViewInit() {
    // for (const ex of this.usersToShow) {
    //   this.userList.push(ex);
    // }
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.updateTableData();
  }

  loadDataOnTable() {
    for (let i = this.userListRange; i < this.userListRange + this.userListAddOnEachScroll; i++) {
      // this.userList.push(this.usersToShow[i]);
    }
    this.userListRange += this.userListAddOnEachScroll;
  }

  updateTableData() {
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.userList);
    this.dataSource.paginator = this.paginator;
  }

  createUser() {
    let url = '/user';
    this.router.navigate([url, 'new']);
  }

  public editUser(user) {
    let url = '/user';
    this.router.navigate([url, user.id]);
  }

}
