<!-- <div class="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center login-fullscreen">
  <div class="uk-position-center">
    <div class="uk-card uk-card-body prama-card">
      <img src="./../../assets/img/creatorLogo.png" class="logo" />
      <div class="uk-margin">
        <span class="uk-form-label yellow">Email</span>
        <div class="uk-form-controls">
          <input class="uk-input prama-input" type="text" (keyup.enter)="login()" [(ngModel)]="this.username" [value]="this.username">
        </div>
      </div>
      <div class="uk-margin">
        <span class="uk-form-label yellow">Password</span>
        <div class="uk-form-controls">
          <input class="uk-input prama-input" type="password" (keyup.enter)="login()" [(ngModel)]="this.password"
            [value]="this.password">
        </div>
      </div>
      <div class="uk-margin">
        <span class="uk-form-label error-message"></span>
        <div class="uk-form-controls">
          <button class="uk-button uk-width-2-3@s uk-margin-small-bottom prama-button" (click)="login()">Sign in
            &nbsp;&nbsp;<i class="fa fa-sign-in faSize"></i></button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="login">
  <div fxLayout="column" fxLayoutAlign="center center" class="login-container">
    <mat-card>
      <mat-card-content class="pb30">
        <div fxLayout="column">
          <img src="./../../assets/img/logo-bootiq.png" class="logo" />
          <mat-form-field>
            <input matInput placeholder="Email" name="exerciseName" (keyup.enter)="login()" [(ngModel)]="this.username"
              [value]="this.username" required>
            <mat-hint class="form-error-message" *ngIf="errorUser && !this.username">
              Name is required
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="pt10">
            <input matInput placeholder="Password" name="password" type="password" (keyup.enter)="login()" [(ngModel)]="this.password"
              [value]="this.password" required>
            <mat-hint class="form-error-message" *ngIf="errorUser && !this.password">
              Password is required
            </mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="errorCredentials" class="error-credentials">
          <span>
            <strong>Invalid email or password</strong><br>
            Please try again with valid access credentials
          </span>
        </div>
      </mat-card-content>
      <mat-card-actions class="text-right nomargin">        
        <button mat-flat-button class="prama-button" (click)="login()">
          LOGIN <mat-icon>arrow_forward</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>