import { Component, OnInit ,AfterViewInit, Output, EventEmitter, Input } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { ProgramService } from "../../services/program.service";
import { WorkoutModeService } from "../../services/workoutmode.service";
import { SessionService } from "../../services/session.service";
import { NotificationService } from "../../services/notification.service";

import { ISessionFilter } from "../../interfaces/models/i-session-filter";
import { ISession } from "../../interfaces/models/i-session";
import { IProgram } from "../../interfaces/models/i-program";
import { IWorkoutMode } from "../../interfaces/models/i-workout-mode";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: "ambience-list",
  templateUrl: "./ambience-list.component.html",
  styleUrls: ["./ambience-list.component.css"]
})
export class AmbienceListComponent implements OnInit {

  @Input() currentAmbience: any = {};
  @Output() ambienceSelected = new EventEmitter();

  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    desc: "Description",
    workMode: "Description",
    actions: ""
  };

  filterId: string = "";
  filterName: string = "";
  filterWorkoutModes: number[] = [];
  filterPrograms: number[] = [];

  workoutModes: IWorkoutMode[];
  programs: IProgram[];
  sessions: ISession[];
  ambiencesToShow: any[];
  currentFilter: ISessionFilter;

  mediaUrl: string = CONFIG.sessionMediaURL;

  displayedColumns = ['image', 'sessionName'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private programsService: ProgramService,
    private sessionService: SessionService,
    private workoutModesService: WorkoutModeService,
    private notificationService: NotificationService) {

    this.ambiencesToShow = this.route.snapshot.data.ambiencesAvailable;

    this.currentFilter = {
      filteredId: "",
      filteredName: "",
      filteredWorkoutModes: [],
      filteredPrograms: []
    };

  }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterViewInit() {    
    this.updateTableData();    
  }

  updateTableData(){
    this.dataSource = new MatTableDataSource(this.ambiencesToShow);
  }

  selectAmbience(ambience: any){
    this.ambienceSelected.emit(ambience);
  }

}
